import React, { FunctionComponent } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { contactFormType, ContactFormType } from '../types';
import { emailRegex } from '../helpers/validators';

const ContactForm: FunctionComponent<ContactFormType> = ({ onFormSubmit }) => {
  const onSubmit = (values: contactFormType) => {
    onFormSubmit(values);
  };

  const validate = (values: contactFormType) => {
    const errors: Partial<contactFormType> = {};

    if (!values.name) {
      errors.name = 'Campo obligatorio';
    } else if (values.name.length < 2) {
      errors.email = 'Por favor ingrese un nombre válido';
    }

    if (!values.email) {
      errors.email = 'Campo obligatorio';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Por favor ingrese un email válido';
    }

    if (!values.msg) {
      errors.msg = 'Campo obligatorio';
    }

    return errors;
  };

  const {
    form, handleSubmit, values, pristine, submitting,
  } = useForm({
    onSubmit,
    validate,
  });

  const email = useField('email', form);
  const name = useField('name', form);
  const msg = useField('msg', form);

  return (
    <form className="contactForm" onSubmit={handleSubmit}>
      <div className="inputWrapper">
        <input className="textInput" type="text" {...email.input} placeholder="Email" />
        {email.meta.touched && email.meta.error && (
          <span className="errorText">{email.meta.error}</span>
        )}
      </div>
      <div className="inputWrapper">
        <input className="textInput" type="text" {...name.input} placeholder="Nombre" />
        {name.meta.touched && name.meta.error && (
          <span className="errorText">{name.meta.error}</span>
        )}
      </div>
      <div className="inputWrapper">
        <textarea className="textInput" {...msg.input} placeholder="Tu mensaje..." />
        {msg.meta.touched && msg.meta.error && (
          <span className="errorText">{msg.meta.error}</span>
        )}
      </div>
      <input type="submit" className="btn small" value="Enviar" disabled={pristine || submitting || !values} />
    </form>
  );
};

export default ContactForm;
