import { UserType, usersPayloadType } from '../types';

export const updateCurrentUserHelper = (currentUser: UserType) => {
  if (currentUser.token && currentUser._id) {
    window.localStorage.setItem('userToken', currentUser.token);
    window.localStorage.setItem('userId', currentUser._id);
  }

  return currentUser;
};

export const deleteUserHelper = (users: UserType[], userToDelete: string) => {
  const filteredUsers = users.filter((user) => user._id !== userToDelete);

  return filteredUsers;
};

export const activateUsersHelper = (users: UserType[], userId: string) => {
  const tempUsers = users;
  const userToEdit = users.findIndex((user: UserType) => user._id === userId);
  tempUsers[userToEdit].isVerified = true;

  return tempUsers;
};

export const updateUsersAbonoHelper = (users: UserType[], payload: usersPayloadType) => {
  const { userId, abono } = payload;
  const tempUsers = users;
  const userToEdit = users.findIndex((user: UserType) => user._id === userId);
  tempUsers[userToEdit].abonoRemaining += Number(abono);

  return tempUsers;
};
