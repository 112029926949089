import React, { FunctionComponent } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { Link } from 'react-router-dom';
import { UserType, ResetPw } from '../types';
import { passwordRegex } from '../helpers/validators';

const ResetPasswordForm: FunctionComponent<ResetPw> = ({ onFormSubmit }) => {
  const onSubmit = (values: Pick<UserType, 'password'>) => {
    if (values.password) {
      onFormSubmit(values.password);
    }
  };

  const validate = (values: Pick<UserType, 'password'>) => {
    const errors: Partial<Pick<UserType, 'password'>> = {};

    if (!values.password) {
      errors.password = 'Campo obligatorio';
    } else if (!passwordRegex.test(values.password)) {
      errors.password = 'tu contraseña debe tener al menos 6 caracteres, una mayúscula, una minúscula y un número';
    }

    return errors;
  };

  const {
    form, handleSubmit, values, pristine, submitting,
  } = useForm({
    onSubmit,
    validate,
  });

  const password = useField('password', form);

  return (
    <>
      <form className="inscripcion formWrapper" onSubmit={handleSubmit}>
        <div className="inputWrapper">
          <input className="textInput" type="password" {...password.input} placeholder="Contraseña" />
          {password.meta.touched && password.meta.error && (
            <span className="errorText">{password.meta.error}</span>
          )}
        </div>
        <input type="submit" className="btn" value="Enviar" disabled={pristine || submitting || !values} />
      </form>
      <p>¿Recordaste tu contraseña? <Link to="/login">Ingresá acá</Link></p>
    </>
  );
};

export default ResetPasswordForm;
