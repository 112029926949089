import React, { createContext } from 'react';
import UseUsers from '../hooks/usersHook';
import { UserContext } from '../types';

export const UsersContext = createContext({} as UserContext);

export const UsersProvider = ({ children }: any) => {
  const { users, manageUsers } = UseUsers();

  return <UsersContext.Provider value={{ users, manageUsers }}>{children}</UsersContext.Provider>;
};
