import React, { FunctionComponent, useState } from 'react';
import { OnSubmitStudentType, ClaseCardType, ClaseType } from '../types';
import StudentList from './StudentList';
import SignUpToClassForm from './SignUpToClassForm';
import AddStudentForm from './AddStudentForm';
import ClaseEditor from './ClaseEditor';
import { days } from '../helpers/consts';

const ClaseCard: FunctionComponent<ClaseCardType> = ({
  clase,
  isAdmin,
  hasAbono,
  AddStudent,
  RemoveStudent,
  RemoveAllStudents,
  DeleteCurrentClase,
  EditCurrentClase,
}) => {
  const {
    name, teacher, startTime, endTime, day, _id, quota, students,
  } = clase;

  const [editMode, toggleEditMode] = useState(false);

  const CreateNewStudent: OnSubmitStudentType = (studentId, claseId) => {
    AddStudent(studentId, claseId);
  };

  const EmptyStudents = (claseId: string) => {
    if (claseId && RemoveAllStudents) {
      RemoveAllStudents(claseId);
    }
  };

  const deleteStudent = (studentId: string, claseId: string) => {
    if (RemoveStudent) {
      RemoveStudent(studentId, claseId);
    }
  };

  const onDelete = () => {
    if (DeleteCurrentClase && _id) {
      DeleteCurrentClase(_id);
    }
  };

  const onEdit = (editedClase: ClaseType) => {
    if (_id && EditCurrentClase) {
      EditCurrentClase(_id, editedClase);
    }
  };

  return (
    <div className="box">
      {_id && isAdmin && (
        <>
          {!editMode && (
            <>
              <h2 className="title">{name}</h2>
              <h3 className="subtitle">
                {days[day]} {startTime} a {endTime}
              </h3>
              {isAdmin && <h3 className="subtitle">instructor: {teacher}</h3>}
            </>
          )}
          {editMode && <h2 className="title">Editar Clase</h2>}
          <div className="buttonsWrapper">
            <button type="button" className="btn small" onClick={onDelete}>
              <i className="icon icon-delete" />
            </button>
            <button
              type="button"
              className="btn small"
              onClick={() => toggleEditMode(!editMode)}
            >
              {!editMode && <i className="icon icon-edit" />}
              {editMode && <i className="icon icon-cancel" />}
            </button>
          </div>
          {editMode && (
            <ClaseEditor onFormSubmit={onEdit} currentClase={clase} />
          )}
          {!editMode && (
            <>
              <div className="students-container">
                <div className="confirmed-students">
                  <h6 className="title">Alumnos anotados</h6>
                  <StudentList
                    students={students}
                    deleteStudent={deleteStudent}
                    claseId={_id || ''}
                  />
                  <button
                    type="button"
                    className={
                      students.length === 0
                        ? 'btn small hidden'
                        : 'btn small deleteAll'
                    }
                    onClick={() => EmptyStudents(_id || '')}
                  >
                    Eliminar todos los alumnos
                  </button>
                </div>
              </div>
              <AddStudentForm
                onFormSubmit={CreateNewStudent}
                claseId={_id}
                students={students}
                isClassFull={students.length >= Number(quota)}
              />
            </>
          )}
        </>
      )}
      {_id && !isAdmin && hasAbono && (
        <>
          <i className="icon icon-write" />
          <div className="claseInfo">
            ¿Querés anotarte para {name} el {days[day]} a las {startTime}
            {isAdmin && ` con ${teacher}`}?
          </div>
          <SignUpToClassForm
            onSubmit={CreateNewStudent}
            claseId={_id}
            students={students}
            isClassFull={students.length > Number(quota)}
          />
        </>
      )}
      {_id && !isAdmin && !hasAbono && (
        <div className="messageWrapper">
          <i className="icon icon-write" />
          <p className="title">¡No te quedan clases en tu abono!</p>
          <p className="">
            Comunicate con nosotros para agregar más{' '}
            <a href="https://api.whatsapp.com/send?l=es&phone=5492974000358">
              acá.
            </a>
          </p>
        </div>
      )}
      {!isAdmin && clase.students.length >= Number(clase.quota) && (
        <div className="messageWrapper">
          <i className="icon icon-write" />
          <p className="title">Esta clase ya está llena</p>
        </div>
      )}
    </div>
  );
};

export default ClaseCard;
