import { useState, useContext } from 'react';
import { AxiosError } from 'axios';
import { GeneralContext } from '../context/generalContext';
import { updateCurrentUserHelper } from '../helpers/usersHelpers';
import { useAuthProps, UserType } from '../types';
import { emptyUser } from '../helpers/consts';
import {
  LogIn, signUp, verifyEmail, resendConfirmation, GetSingleUser, ForgotPasswordReq, ResetPasswordReq,
} from '../services/UsersService';

const UseAuth = () => {
  const [authUser, setAuthUser] = useState(emptyUser);
  const { manageGeneral } = useContext(GeneralContext);

  const UserLogOut = () => {
    manageGeneral({ action: 'setStatus', newStatus: { code: 200, action: 'logout' } });
    setAuthUser(emptyUser);
  };

  const UserLogIn = (userCred: Pick<UserType, 'email' | 'password'>) => {
    LogIn(userCred)
      .then((data) => {
        const { user } = data.data;
        user.token = data.data.token;

        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'login' } });
        setAuthUser(updateCurrentUserHelper(user));
      })
      .catch((err: AxiosError) => {
        manageGeneral({
          action: 'setStatus',
          newStatus: {
            code: err.response ? err.response.status : 404,
            action: 'login',
            message: err.response && err.response.data.type,
          },
        });
      });
  };

  const UserSignUp = (signUpUser: UserType) => {
    signUp(signUpUser)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'signup' } });
      })
      .catch((err: AxiosError) => {
        manageGeneral({
          action: 'setStatus',
          newStatus: {
            code: err.response ? err.response.status : 404,
            action: 'signup',
            message: err.response && err.response.data.type,
          },
        });
      });
  };

  const UserVerify = (token: string) => {
    verifyEmail(token)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'verify', message: 'verify-success' } });
      })
      .catch((err: AxiosError) => {
        manageGeneral({
          action: 'setStatus',
          newStatus: {
            code: err.response ? err.response.status : 404,
            action: 'verify',
            message: err.response && err.response.data.type,
          },
        });
      });
  };

  const UserResendEmail = (email: string) => {
    resendConfirmation(email)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'resendEmail', message: data.message } });
      })
      .catch((err: AxiosError) => {
        manageGeneral({
          action: 'setStatus',
          newStatus: {
            code: err.response ? err.response.status : 404,
            action: 'verify',
            message: err.response && err.response.data.type,
          },
        });
      });
  };

  const UserGetOne = (token: string, userId: string) => {
    GetSingleUser(token, userId)
      .then((data) => {
        const { user } = data.data;
        user.token = token;
        setAuthUser(updateCurrentUserHelper(user));

        manageGeneral({
          action: 'setStatus',
          newStatus: {
            code: data.status,
            action: 'userGetOne',
          },
        });
      })
      .catch((err: AxiosError) => {
        manageGeneral({
          action: 'setStatus',
          newStatus: {
            code: err.response ? err.response.status : 404,
            action: 'getSingleUser',
            message: err.response && err.response.data.type,
          },
        });
      });
  };

  const ForgotPassword = (email: string) => {
    ForgotPasswordReq(email)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'forgotPassword' } });
      })
      .catch((err: AxiosError) => {
        manageGeneral({
          action: 'setStatus',
          newStatus: {
            code: err.response ? err.response.status : 404,
            action: 'forgotPassword',
            message: err.response && err.response.data.type,
          },
        });
      });
  };

  const ResetPassword = (password: string, token: string, userId: string) => {
    ResetPasswordReq(password, token, userId)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'resetPassword' } });
      })
      .catch((err: AxiosError) => {
        manageGeneral({
          action: 'setStatus',
          newStatus: {
            code: err.response ? err.response.status : 404,
            action: 'resetPassword',
            message: err.response && err.response.data.type,
          },
        });
      });
  };

  const UserAbonoUpdate = (abono: string) => {
    authUser.abonoRemaining += Number(abono);
  };

  const manageAuthUser = (props: useAuthProps) => {
    const {
      action, token, loginUser, signUpUser, email, userId, password, abono,
    } = props;

    switch (action) {
      case 'login':
        return loginUser && UserLogIn(loginUser);
      case 'signup':
        return signUpUser && UserSignUp(signUpUser);
      case 'logout':
        return UserLogOut();
      case 'verify':
        return token && UserVerify(token);
      case 'resendVerificationEmail':
        return email && UserResendEmail(email);
      case 'getSingleUser':
        return token && userId && UserGetOne(token, userId);
      case 'requestPasswordChange':
        return email && ForgotPassword(email);
      case 'resetPassword':
        return password && token && userId && ResetPassword(password, token, userId);
      case 'updateAbono':
        return abono && UserAbonoUpdate(abono);
      default:
        return '';
    }
  };

  return {
    authUser,
    manageAuthUser,
  };
};

export default UseAuth;
