import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GeneralContext } from '../context/generalContext';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import { AuthContext } from '../context/authContext';

const ForgotPassword = () => {
  const { general, manageGeneral } = useContext(GeneralContext);
  const { status, loading } = general;
  const { manageAuthUser } = useContext(AuthContext);

  useEffect(() => {
    if (loading) {
      manageGeneral({ action: 'setLoading' });
    }
  }, []);

  useEffect(() => {
    if (status && status.action === 'forgotPassword' && loading) {
      manageGeneral({ action: 'setLoading' });
    }
  }, [status, loading, manageGeneral]);

  const RequestNewPassword = (email: string) => {
    manageGeneral({ action: 'setLoading' });
    manageAuthUser({ action: 'requestPasswordChange', email });
  };

  return (
    <div className="pageWrapper" id="password">
      <h1 className="title">Recuperar contraseña</h1>
      {status && status.action === 'forgotPassword' && status.code !== 201 && status.message !== 'email-not-exist'
        && (
          <span className="errorText"> Los datos ingresados no son correctos. Por favor intentá nuevamente. </span>
        )}
      {status && status.action === 'forgotPassword' && status.message && status.message === 'email-not-exist'
        && <span className="errorText">No tenemos ninguna cuenta registrada con esta direccción de email.</span> }
      {status && status.action === 'forgotPassword' && status.code === 201
        && (
          <>
            <i className="icon icon-heart" />
            <p className="title">¡Gracias!</p>
            <p className="subtitle">
              Te enviamos un mail para que cambies tu contraseña. Por favor revisa tu casilla de correo, incluyendo la bandeja de correo no deseado.
            </p>
            <Link className="btn" to="/">Volver</Link>
          </>
        )}
      {((status && status.code !== 201) || !status) && <ForgotPasswordForm onFormSubmit={RequestNewPassword} />}
    </div>
  );
};

export default ForgotPassword;
