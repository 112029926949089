import React, {
  FunctionComponent, useState, useEffect, useContext,
} from 'react';
import { ClaseType, ClaseMobileHomeProps } from '../types';
import OrderByTime from '../helpers/orderByTime';
import ClaseRow from './ClaseMobileRow';
import { ClasesContext } from '../context/clasesContext';
import { AuthContext } from '../context/authContext';

const ClaseMobileHome: FunctionComponent<ClaseMobileHomeProps> = ({ isAdminPanel }) => {
  const { clases } = useContext(ClasesContext);
  const { authUser } = useContext(AuthContext);

  const [filteredClases, setFilteredClases] = useState<ClaseType[] | undefined>();
  const isAdmin = Boolean(authUser && authUser.roles && authUser.roles.find((role: string) => role === 'Admin'));

  useEffect(() => {
    if (!isAdminPanel) {
      const noMuscClases = clases.filter((clase: ClaseType) => clase.category !== 'musculacion');
      setFilteredClases(noMuscClases.sort(OrderByTime));
    } else {
      setFilteredClases(clases.sort(OrderByTime));
    }
  }, [clases, isAdminPanel]);

  const filterCategory = (e: any) => {
    const option = e.target.value;
    e.preventDefault();
    let orderedClases: ClaseType[] = [];

    if (!isAdminPanel) {
      const noMuscClases = clases.filter((clase: ClaseType) => clase.category !== 'musculacion');
      orderedClases = noMuscClases.sort(OrderByTime);
    } else {
      orderedClases = clases;
    }

    if (option === '') {
      return setFilteredClases(orderedClases);
    }

    return setFilteredClases(orderedClases.filter((clase: ClaseType) => clase.category === option));
  };

  const today = new Date().getDay();
  const monday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'monday') : [];
  const tuesday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'tuesday') : [];
  const wednesday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'wednesday') : [];
  const thursday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'thursday') : [];
  const friday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'friday') : [];
  const saturday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'saturday') : [];

  return (
    <>
      {clases.length > 0
        && (
          <div className="inputWrapper categoryFilter">
            <div className="arrow">▼</div>
            <select className="select" onChange={filterCategory}>
              <option className="selectOption" value="">Todas las categorías</option>
              <option className="selectOption" value="martial">Artes Marciales</option>
              <option className="selectOption" value="fitness">Fitness</option>
              <option className="selectOption" value="indoor">Indoor</option>
              { isAdminPanel && <option className="selectOption" value="musculacion">Musculación</option>}
              <option className="selectOption" value="infantil">Infantil</option>
            </select>
          </div>
        )}
      <div className="clasesMobileWrapper">
        {clases.length === 0 && <span className="helperText">No hay actividades disponibles en este momento</span>}
        {clases.length > 0
        && filteredClases
        && filteredClases.length === 0
        && <span className="helperText">No hay actividades en esta categoría</span>}
        {filteredClases && filteredClases.length > 0
          && (
            <>
              <div className="dayWrapper">
                <span className="dayTitle">Lunes</span>
                {monday.length > 0 ? monday.map((clase: ClaseType) => (
                  <ClaseRow isDisabled={today > 1 || clase.students.length >= Number(clase.quota)} clase={clase} isAdmin={isAdmin} key={clase._id} />
                )) : <p className="helperText">No hay actividades este día.</p>}
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Martes</span>
                {tuesday.length > 0 ? tuesday.map((clase: ClaseType) => (
                  <ClaseRow isDisabled={today > 2 || clase.students.length >= Number(clase.quota)} clase={clase} key={clase._id} isAdmin={isAdmin} />
                )) : <p className="helperText">No hay actividades este día.</p>}
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Miércoles</span>
                {wednesday.length > 0 ? wednesday.map((clase: ClaseType) => (
                  <ClaseRow isDisabled={today > 3 || clase.students.length >= Number(clase.quota)} clase={clase} key={clase._id} isAdmin={isAdmin} />
                )) : <p className="helperText">No hay actividades este día.</p>}
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Jueves</span>
                {thursday.length > 0 ? thursday.map((clase: ClaseType) => (
                  <ClaseRow clase={clase} isDisabled={today > 4 || clase.students.length >= Number(clase.quota)} key={clase._id} isAdmin={isAdmin} />
                )) : <p className="helperText">No hay actividades este día.</p>}
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Viernes</span>
                {friday.length > 0 ? friday.map((clase: ClaseType) => (
                  <ClaseRow clase={clase} isDisabled={today > 5 || clase.students.length >= Number(clase.quota)} key={clase._id} isAdmin={isAdmin} />
                )) : <p className="helperText">No hay actividades este día.</p>}
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Sábado</span>
                {saturday.length > 0 ? saturday.map((clase: ClaseType) => (
                  <ClaseRow clase={clase} isDisabled={today > 6 || clase.students.length >= Number(clase.quota)} key={clase._id} isAdmin={isAdmin} />
                )) : <p className="helperText">No hay actividades este día.</p>}
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default ClaseMobileHome;
