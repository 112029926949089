import { useState, useContext } from 'react';
import { AxiosError } from 'axios';
import { UserType, manageUsersProps } from '../types';
import { GeneralContext } from '../context/generalContext';
import { AuthContext } from '../context/authContext';
import { deleteUserHelper, activateUsersHelper, updateUsersAbonoHelper } from '../helpers/usersHelpers';
import {
  GetUsers, UpdateAbono, ActivateUser, DeleteUser,
} from '../services/UsersService';

const UseUsers = () => {
  const [users, setUsers] = useState<UserType[]>([]);
  const { manageGeneral } = useContext(GeneralContext);
  const { authUser, manageAuthUser } = useContext(AuthContext);

  const UpdateUsers = (token: string) => {
    GetUsers(token)
      .then((data) => setUsers(data))
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'getUsers' } });
      });
  };

  const UpdateUserAbono = (token: string, userId: string, abono: number) => {
    UpdateAbono(token, userId, abono)
      .then((data) => {
        if (data.status === 200 && users.length > 0) {
          if (userId === authUser._id) {
            manageAuthUser({ action: 'updateAbono', abono: String(abono) });
          } else {
            setUsers(updateUsersAbonoHelper(users, { userId, abono }));
          }
        }

        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'updateUserAbono' } });
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'updateUserAbono' } });
      });
  };

  const DeleteSingleUser = (userId: string, token: string) => {
    DeleteUser(userId, token)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'deleteUser' } });

        if (data.status === 200) {
          setUsers(deleteUserHelper(users, userId));
        }
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'deleteUser' } });
      });
  };

  const ActivateSingleUser = (userId: string, token: string) => {
    ActivateUser(userId, token)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'activateUser' } });

        if (data.status === 200) {
          setUsers(activateUsersHelper(users, userId));
        }
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'activateUser' } });
      });
  };

  const manageUsers = (props: manageUsersProps) => {
    const {
      action, token, userId, abono,
    } = props;

    switch (action) {
      case 'updateUsers':
        return token && UpdateUsers(token);
      case 'updateAbono':
        return token && userId && abono && UpdateUserAbono(token, userId, abono);
      case 'deleteUser':
        return userId && token && DeleteSingleUser(userId, token);
      case 'activateUser':
        return userId && token && ActivateSingleUser(userId, token);
      default:
        return '';
    }
  };

  return {
    users,
    manageUsers,
  };
};

export default UseUsers;
