import React, { FunctionComponent } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { Link } from 'react-router-dom';
import { UserType, ForgotPw } from '../types';
import { emailRegex } from '../helpers/validators';

const ForgotPasswordForm: FunctionComponent<ForgotPw> = ({ onFormSubmit }) => {
  const onSubmit = (values: Pick<UserType, 'email'>) => {
    onFormSubmit(values.email);
  };

  const validate = (values: Pick<UserType, 'email'>) => {
    const errors: Partial<Pick<UserType, 'email'>> = {};

    if (!values.email) {
      errors.email = 'Campo obligatorio';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Por favor ingrese un email válido';
    }

    return errors;
  };

  const {
    form, handleSubmit, values, pristine, submitting,
  } = useForm({
    onSubmit,
    validate,
  });

  const email = useField('email', form);

  return (
    <>
      <form className="inscripcion formWrapper" onSubmit={handleSubmit}>
        <div className="inputWrapper">
          <input className="textInput" type="email" {...email.input} placeholder="email" />
          {email.meta.touched && email.meta.error && (
            <span className="errorText">{email.meta.error}</span>
          )}
        </div>
        <input type="submit" className="btn" value="Enviar" disabled={pristine || submitting || !values} />
      </form>
      <p>¿Recordaste tu contraseña? <Link to="/login">Ingresá acá</Link></p>
    </>
  );
};

export default ForgotPasswordForm;
