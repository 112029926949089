import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

type ceo = {
  fields: {
  imagen: {
      fields: {
        title?:string,
        file: {
          url: string
        }
      }
    }
  }
}

const InstagramSection: React.FC<{content: ceo[]}> = ({ content }) => {
  if (content.length === 0) {
    return <></>;
  }

  const perPageCalculation = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };

  return (
    <div id="instagram" className="homeSection s-instagram">
      <a
        className="instagram-title"
        href="https://instagram.com/magnusfightfitness/"
      >
        <i className="icon-instagram icon" />
        <span className="sectionTitle"> @magnusfightfitness
        </span>
      </a>
      <Carousel
        responsive={perPageCalculation}
        infinite
        shouldResetAutoplay={false}
        keyBoardControl
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container customizedSlider"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px slide"
        arrows={content.length > 1}
      >
        {
          content && content.map(({ fields: { imagen: { fields: { title, file: { url } } } } }) => (
            url ? (
              <div key={url}>
                <div className="slideWrapper">
                  <img
                    className="sliderImage"
                    src={url}
                    alt={title}
                  />
                </div>
              </div>
            ) : <></>
          ))
        }
      </Carousel>
    </div>
  );
};

export default InstagramSection;
