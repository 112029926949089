import React, { FunctionComponent, useContext } from 'react';
import { UserType, StudentListType } from '../types';
import { UsersContext } from '../context/usersContext';

const StudentList: FunctionComponent<StudentListType> = ({ students, deleteStudent, claseId }) => {
  const { users } = useContext(UsersContext);

  const fullStudentList: (UserType | undefined)[] = students.map((student) => {
    const studentFound = users.find((user: UserType) => user._id === student);

    return studentFound;
  });

  return (
    <ul className="list">
      {
        fullStudentList.length === 0
          ? <li>No hay alumnos anotados aún</li>
          : fullStudentList.map((student) => student
            && (
              <li className="student" key={student._id}>
                {student.firstName}{' '}{student.lastName}{' - Socio N°: '}{student.gymId}
                <button type="button" className="btn small" onClick={() => student._id && deleteStudent(student._id, claseId)}>
                  <i className="icon icon-delete" />
                </button>
              </li>
            ))
      }
    </ul>
  );
};

export default StudentList;
