import React from 'react';
import { loaderProps } from '../types';

const Loading = ({ isModal = false }: loaderProps) => (
  <div className={isModal ? 'loader-container modalLoader' : 'loader-container'}>
    <div className="loading">
      <div className="loading__circle" />
      <div className="loading__circle" />
      <div className="loading__circle" />
    </div>
  </div>
);

export default Loading;
