import axios from 'axios';

const contentful = require('contentful');

export const ApiClient = axios.create({
  baseURL: 'https://magnus-app-backend.herokuapp.com',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const InstagramClient = axios.create({
  baseURL: 'https://graph.instagram.com',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const contentfulClient = contentful.createClient({
  space: 'g83zgkgj47v4',
  accessToken: `${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
});
