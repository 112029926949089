import React, { FunctionComponent, useContext } from 'react';
import { ButtonEvent, SignUpToClassFormType } from '../types';
import { AuthContext } from '../context/authContext';

const SignUpToClassForm: FunctionComponent<SignUpToClassFormType> = ({
  onSubmit, students, claseId, isClassFull,
}) => {
  const { authUser } = useContext(AuthContext);

  const isStudentAlready = Boolean(students.find((student) => student === authUser._id));

  const handleSubmit = (e: ButtonEvent) => {
    e.preventDefault();
    return claseId && authUser && authUser._id && onSubmit(authUser._id, claseId);
  };

  return (
    <>
      {isStudentAlready && <span className="errorText">Ya estás anotado en esta clase</span>}
      {!isStudentAlready && isClassFull && <span className="errorText">Esta clase no tiene más lugar, por favor elegí otra</span>}
      {!isStudentAlready && !isClassFull && (
        <form className="inscripcion" onSubmit={handleSubmit}>
          <input type="submit" className="btn" value="Anotarse" disabled={isClassFull} />
        </form>
      )}
      <p className="disclaimer">Recordá que anotarte para una clase es un compromiso. La clase será descontada de tu pack de clases.</p>
      <p className="disclaimer">
        Si te anotaste para una clase y no podes asistir,
        <b> por favor avisanos <a href="https://api.whatsapp.com/send?l=es&phone=5492974000358">Acá</a></b>
      </p>
    </>
  );
};

export default SignUpToClassForm;
