import React, { useContext, FunctionComponent } from 'react';
import { useForm, useField } from 'react-final-form-hooks';

import { AddStudentFormType, UserType } from '../types';
import { numberRegex } from '../helpers/validators';
import { UsersContext } from '../context/usersContext';

const AddStudentForm: FunctionComponent<AddStudentFormType> = ({
  onFormSubmit, students, claseId, isClassFull,
}) => {
  const { users } = useContext(UsersContext);

  const getStudentId = (currentGymId: string): string => {
    const foundUser = users.find((user: UserType) => user.gymId === currentGymId);

    if (foundUser && foundUser._id) {
      return foundUser._id;
    }

    return '';
  };

  const isStudentAlready = (gymId: string) => Boolean(students.find((student) => student === getStudentId(gymId)));
  const studentExists = (gymId: string) => Boolean(users.find((user: UserType) => user.gymId === gymId));
  const studentHasAbono = (gymId: string) => {
    const student = users.find((user: UserType) => user.gymId === gymId);
    return Boolean(student && student.abonoRemaining > 0);
  };

  type formValues = {
    gymId: string;
  }

  const validate = (values: formValues) => {
    const errors: Partial<formValues> = {};

    if (!values.gymId) {
      errors.gymId = 'Campo obligatorio';
    } else if (!numberRegex.test(values.gymId)) {
      errors.gymId = 'El ID debe tener solo digitos numéricos';
    } else if (values.gymId.length > 4 || values.gymId.length < 4) {
      errors.gymId = 'El ID debe tener 4 dígitos';
    } else if (!studentExists(values.gymId)) {
      errors.gymId = 'este alumno no existe';
    } else if (isStudentAlready(values.gymId)) {
      errors.gymId = 'este alumno ya esta anotado en la clase';
    } else if (!studentHasAbono(values.gymId)) {
      errors.gymId = 'este alumno no tiene clases en su abono';
    }

    return errors;
  };

  const onSubmit = (values: formValues) => {
    const studentId = getStudentId(values.gymId);

    onFormSubmit(studentId, claseId);
  };

  const {
    form, handleSubmit, values, pristine, submitting,
  } = useForm({
    onSubmit,
    validate,
  });

  const gymId = useField('gymId', form);

  return (
    <>
      <p className="subtitle">Agregar Alumnos</p>
      { isClassFull
        ? <span className="errorText">Esta clase no tiene mas lugar, por favor elije otra</span>
        : (
          <form className="inscripcion" onSubmit={handleSubmit}>
            <>
              <span className="inscripcion-title">Recordá que para agregar un alumno, éste tiene que estar registrado en el sitio.</span>
              <div className="inputWrapper">
                <input className="textInput" type="text" {...gymId.input} placeholder="ID del gimnasio" />
                {gymId.meta.touched && gymId.meta.error && (
                  <span className="errorText">{gymId.meta.error}</span>
                )}
              </div>
              <input type="submit" className="btn" value="Agregar Alumno" disabled={pristine || submitting || !values} />
            </>
          </form>
        )}
    </>
  );
};

export default AddStudentForm;
