import React, {
  FunctionComponent, useState, useContext,
} from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { OnSubmitStudentType, ClaseType, ClaseTableItemProps } from '../types';
import { GeneralContext } from '../context/generalContext';
import ClaseCard from '../components/ClaseCard';
import Loader from '../components/Loading';
import { ClasesContext } from '../context/clasesContext';
import { AuthContext } from '../context/authContext';
import { days } from '../helpers/consts';

const ClaseTableItem: FunctionComponent<ClaseTableItemProps> = ({ clase, isAdmin, isDisabled }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { general, manageGeneral } = useContext(GeneralContext);
  const { manageClases } = useContext(ClasesContext);
  const { authUser } = useContext(AuthContext);

  const { loading, status } = general;
  const {
    name, startTime, endTime, _id, teacher, day, category,
  } = clase;

  const toggleModal = () => {
    if (status) { manageGeneral({ action: 'setStatus', newStatus: undefined }); }
    setModalOpen(!isModalOpen);
  };

  const AddStudent: OnSubmitStudentType = (studentId, claseId) => {
    manageGeneral({ action: 'setLoading' });
    manageClases({
      action: 'addStudent', claseId, token: authUser.token, studentId,
    });
  };

  const RemoveStudent = (studentId: string, claseId: string) => {
    manageGeneral({ action: 'setLoading' });
    manageClases({
      action: 'deleteSingleStudent', claseId, token: authUser.token, studentId,
    });
  };

  const RemoveAllStudents = (claseId: string) => {
    manageGeneral({ action: 'setLoading' });
    manageClases({
      action: 'deleteAllStudents', claseId, token: authUser.token,
    });
  };

  const DeleteCurrentClase = (claseId: string) => {
    manageGeneral({ action: 'setLoading' });
    manageClases({
      action: 'delete', claseId, token: authUser.token,
    });
  };

  const EditCurrentClase = (claseId: string, data: ClaseType) => {
    manageGeneral({ action: 'setLoading' });
    manageClases({
      action: 'updateOne', claseId, token: authUser.token, dataToUpdate: data,
    });
  };

  return (
    <div className={isDisabled ? 'ClaseTableItem disabled' : 'ClaseTableItem'}>
      <div>
        <span className="time">{startTime} - {endTime}</span>
        <mark className={`title ${category}`}>{name}</mark>
        {isAdmin && <span className="teacher">{teacher}</span>}
        {
          _id && (
            !isAdmin ? (<button type="button" disabled={isDisabled} onClick={toggleModal} className="btn small">Anotarse</button>)
              : (<button type="button" onClick={toggleModal} className="btn small">Editar</button>)
          )
        }
        <hr className="dayBottomDivider" />
      </div>
      <ReactModal
        isOpen={isModalOpen}
        contentLabel="Minimal Modal Example"
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick
        className="modal"
        overlayClassName="overlay"
        ariaHideApp={false}
      >
        <i className="icon icon-close" onClick={toggleModal} role="button" tabIndex={0} />
        {loading && <Loader isModal />}

        {!loading && status && (
          status.action === 'addStudent'
          || status.action === 'deleteSingleStudent'
          || status.action === 'deleteAllStudents'
          || status.action === 'deleteClase'
          || status.action === 'updateClase'
          || status.action === 'updateUserAbono'
        ) && status.code !== 200
          && status.code !== 422
          && <p className="errorText">Tu sesión ha expirado. Por favor ingresá e intentá nuevamente.</p>}
        {!loading && status
          && status.action === 'addStudent'
          && status.code === 422
          && <p className="title">¡Lo sentimos, ya no queda lugar en esta clase! Por favor, elegí otra.</p>}
        {!loading && status
          && (status.action === 'addStudent' || status.action === 'updateUserAbono')
          && status.code === 200 && !isAdmin && (
            <div className="messageWrapper">
              <i className="icon icon-thumbsup" />
              <p className="title">Te anotaste para {name} el {days[day]} a las {startTime}{isAdmin && ` con ${teacher}`}  </p>
              <p className="disclaimer">Recordá que anotarte para una clase es un compromiso. La clase será descontada de tu pack de clases.</p>
              <p className="disclaimer">
                Si te anotaste para una clase y no podes asistir,
                <b> por favor avisanos <a href="https://api.whatsapp.com/send?l=es&phone=5492974000358">Acá</a></b>
              </p>
            </div>
        )}

        {!loading && status && (
          status.action === 'addStudent'
          || status.action === 'deleteSingleStudent'
          || status.action === 'deleteAllStudents'
          || status.action === 'deleteClase'
          || status.action === 'updateClase'
          || status.action === 'updateUserAbono'
          || status.action === 'updateAllClases'
        ) && status.code === 200 && isAdmin && (
        <div className="messageWrapper">
          <i className="icon icon-thumbsup" />
          <p className="title">Clase actualizada exitosamente</p>
          <button type="button" className="btn" onClick={() => manageGeneral({ action: 'setStatus', newStatus: undefined })}>Volver</button>
        </div>
        )}
        {!loading && (
          !status || (status && (
            status.action !== 'addStudent'
            && status.action !== 'deleteSingleStudent'
            && status.action !== 'deleteAllStudents'
            && status.action !== 'deleteClase'
            && status.action !== 'updateClase'
            && status.action !== 'updateUserAbono'
            && status.action !== 'updateAllClases')
          )) && authUser._id && (
            <ClaseCard
              RemoveAllStudents={RemoveAllStudents}
              RemoveStudent={RemoveStudent}
              clase={clase}
              isAdmin={isAdmin}
              AddStudent={AddStudent}
              key={clase.name}
              DeleteCurrentClase={DeleteCurrentClase}
              EditCurrentClase={EditCurrentClase}
              hasAbono={authUser.abonoRemaining > 0}
            />
        )}
        {!loading
          && (!status
          || (status && status.action !== 'addStudent')
          || (status && status.action !== 'deleteSingleStudent')
          || (status && status.action !== 'deleteAllStudents')
          || (status && status.action !== 'deleteClase')
          || (status && status.action !== 'updateClase')
          || (status && status.action !== 'updateUserAbono')
          ) && !authUser._id
          && (
            <div className="messageWrapper">
              <i className="icon icon-write" />
              <p className="title">Registrate en el sitio para anotarte a las clases!</p>
              <Link to="/registro" className="btn small">Registrarme</Link>
            </div>
          )}
      </ReactModal>
    </div>
  );
};

export default ClaseTableItem;
