import React, { useContext } from 'react';
import { ClaseType } from '../types';
import { ClasesContext } from '../context/clasesContext';
import { AuthContext } from '../context/authContext';
import { days } from '../helpers/consts';

const ProfilePage = () => {
  const { clases } = useContext(ClasesContext);
  const { authUser } = useContext(AuthContext);

  const {
    _id,
    firstName,
    lastName,
    email,
    gymId,
    abonoRemaining,
  } = authUser;

  const reducer = (total: ClaseType[], current: ClaseType) => {
    const isOnCurrentClase = current.students.find((student) => student === _id);
    const isOnTotalClases = total.find((clase) => clase._id === current._id);

    if (current._id && isOnCurrentClase && !isOnTotalClases) {
      return total.concat(current);
    }

    return total;
  };

  const userClasses = clases.reduce(reducer, []);

  return (
    <div id="profile" className="pageWrapper">
      <i className="icon icon-profile" />
      <h1 className="title">¡Hola, {firstName}!</h1>
      <div className="detailsContainer">
        <p><span className="profileLabel">Nombre:</span> {firstName} {lastName}</p>
        <p><span className="profileLabel">Email:</span> {email} </p>
        <p><span className="profileLabel">ID del gimnasio:</span> #{gymId}</p>
        <p><span className="profileLabel">Clases disponibles en tu pack:</span> {abonoRemaining} </p>

      </div>
      <div className="claseContainer">
        <p className="profileLabel">Clases en las que te anotaste:</p>
        {
          (userClasses.length < 1)
            ? <span>No estás inscripto a ninguna clase.</span>
            : userClasses.map((clase: ClaseType) => <p>{clase.name} el {days[clase.day]} a las {clase.startTime}</p>)
        }
      </div>
      <p className="disclaimer">
        Si te anotaste para una clase y no podes asistir,
        por favor avisanos <a href="https://api.whatsapp.com/send?l=es&phone=5492974000358">Acá</a>
      </p>
    </div>
  );
};

export default ProfilePage;
