import React, { FunctionComponent } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import {
  ClaseType, ClaseEditorType, claseEditorForm,
} from '../types';
import { numberRegex } from '../helpers/validators';
import { hours, minutes } from '../helpers/consts';

const ClaseEditor: FunctionComponent<ClaseEditorType> = ({ onFormSubmit, currentClase }) => {
  const splitStartTime = currentClase.startTime.split(':');
  const splitEndTime = currentClase.endTime.split(':');

  const onSubmit = (values: claseEditorForm) => {
    const clase: ClaseType = {
      _id: currentClase._id,
      name: values.name,
      teacher: values.teacher,
      startTime: `${values.startTimeHr}:${values.startTimeMin}`,
      endTime: `${values.endTimeHr}:${values.endTimeMin}`,
      day: values.day,
      quota: values.quota,
      category: values.category,
      students: [],
    };

    onFormSubmit(clase);
  };

  type ErrorType = {
    [key: string]: string
  }

  const validate = (values: claseEditorForm) => {
    const {
      name, teacher, startTimeHr, startTimeMin, endTimeHr, endTimeMin, quota, day, category,
    } = values;

    const errors: ErrorType = {};

    if (!name) {
      errors.name = 'Campo obligatorio';
    }
    if (!teacher) {
      errors.teacher = 'Campo obligatorio';
    }
    if (!startTimeHr) {
      errors.startTimeHr = 'Campo obligatorio';
    }
    if (!startTimeMin) {
      errors.startTimeMin = 'Campo obligatorio';
    }
    if (!endTimeHr) {
      errors.endTimeHr = 'Campo obligatorio';
    }
    if (!endTimeMin) {
      errors.endTimeMin = 'Campo obligatorio';
    }
    if (Number(startTimeHr) > Number(endTimeHr) || ((Number(startTimeHr) === Number(endTimeHr) && Number(startTimeMin) > Number(endTimeMin)))) {
      errors.startTimeHr = 'la hora de comienzo debe ser menor que la de fin';
    }
    if (!quota) {
      errors.quota = 'Campo obligatorio';
    } else if (!numberRegex.test(quota)) {
      errors.quota = 'El cupo debe tener solo digitos numéricos';
    }
    if (!day) {
      errors.day = 'Campo obligatorio';
    }
    if (!category) {
      errors.category = 'Campo obligatorio';
    }
    return errors;
  };

  const {
    form, handleSubmit, values, pristine, submitting,
  } = useForm({
    onSubmit,
    validate,
    initialValues: {
      name: currentClase.name,
      teacher: currentClase.teacher,
      startTimeHr: splitStartTime[0],
      startTimeMin: splitStartTime[1],
      endTimeHr: splitEndTime[0],
      endTimeMin: splitEndTime[1],
      day: currentClase.day,
      quota: currentClase.quota,
      category: currentClase.category,
    },
  });

  const name = useField('name', form);
  const teacher = useField('teacher', form);
  const quota = useField('quota', form);
  const day = useField('day', form);
  const category = useField('category', form);
  const startTimeHr = useField('startTimeHr', form);
  const startTimeMin = useField('startTimeMin', form);
  const endTimeMin = useField('endTimeMin', form);
  const endTimeHr = useField('endTimeHr', form);

  return (
    <>
      <form onSubmit={handleSubmit}>

        <div className="formColumns">
          <div className="inputWrapper">
            <input className="textInput" type="text" {...name.input} placeholder="Clase" />
            {name.meta.touched && name.meta.error && (
              <span className="errorText">{name.meta.error}</span>
            )}
          </div>
          <div className="inputWrapper">
            <input className="textInput" type="text" {...teacher.input} placeholder="Instructor" />
            {teacher.meta.touched && teacher.meta.error && (
              <span className="errorText">{teacher.meta.error}</span>
            )}
          </div>
        </div>
        <div className="formColumns">
          <div className="smallSelectWrapper">
            <span className="selectLabel">Hora de comienzo de la clase</span>
            <div className="inputWrapper">
              <div className="arrow">▼</div>
              <select className="select" {...startTimeHr.input}>
                {hours.map((hour: string) => <option className="selectOption" key={`start${hour}`} value={hour}>{hour}</option>)}
              </select>
              {startTimeHr.meta.touched && startTimeHr.meta.error && (
                <span className="errorText">{startTimeHr.meta.error}</span>
              )}
            </div>
            <div className="inputWrapper">
              <div className="arrow">▼</div>
              <select className="select" {...startTimeMin.input}>
                {minutes.map((minute: string) => <option className="selectOption" key={`start${minute}`} value={minute}>{minute}</option>)}
              </select>
              {startTimeMin.meta.touched && startTimeMin.meta.error && (
                <span className="errorText">{startTimeMin.meta.error}</span>
              )}
            </div>
          </div>
          <div className="smallSelectWrapper">
            <span className="selectLabel">Hora de fin de la clase</span>
            <div className="inputWrapper">
              <div className="arrow">▼</div>
              <select className="select" {...endTimeHr.input}>
                {hours.map((hour: string) => <option className="selectOption" key={`end${hour}`} value={hour}>{hour}</option>)}
              </select>
              {endTimeHr.meta.touched && endTimeHr.meta.error && (
                <span className="errorText">{endTimeHr.meta.error}</span>
              )}
            </div>
            <div className="inputWrapper">
              <div className="arrow">▼</div>
              <select className="select" {...endTimeMin.input}>
                {minutes.map((minute: string) => <option className="selectOption" key={`end${minute}`} value={minute}>{minute}</option>)}
              </select>
              {endTimeMin.meta.touched && endTimeMin.meta.error && (
                <span className="errorText">{endTimeMin.meta.error}</span>
              )}
            </div>
          </div>
        </div>
        <div className="formColumns three">
          <div className="inputWrapper">
            <input className="textInput" type="text" {...quota.input} placeholder="Cupo" />
            {quota.meta.touched && quota.meta.error && (
              <span className="errorText">{quota.meta.error}</span>
            )}
          </div>
          <div className="inputWrapper">
            <div className="arrow">▼</div>
            <select className="select" {...day.input}>
              <option className="selectOption" value="">Elegí un día</option>
              <option className="selectOption" value="monday">Lunes</option>
              <option className="selectOption" value="tuesday">Martes</option>
              <option className="selectOption" value="wednesday">Miercoles</option>
              <option className="selectOption" value="thursday">Jueves</option>
              <option className="selectOption" value="friday">Viernes</option>
              <option className="selectOption" value="saturday">Sabado</option>
            </select>
            {day.meta.touched && day.meta.error && (
              <span className="errorText">{day.meta.error}</span>
            )}
          </div>
          <div className="inputWrapper">
            <div className="arrow">▼</div>
            <select className="select" {...category.input}>
              <option className="selectOption" value="">Elegir categoría</option>
              <option className="selectOption" value="martial">Artes Marciales</option>
              <option className="selectOption" value="fitness">Fitness</option>
              <option className="selectOption" value="musculacion">Musculacion</option>
              <option className="selectOption" value="indoor">Indoor</option>
              <option className="selectOption" value="infantil">Infantil</option>
            </select>
            {category.meta.touched && category.meta.error && (
              <span className="errorText">{category.meta.error}</span>
            )}
          </div>
        </div>
        <input type="submit" className="btn" value="Editar Clase" disabled={pristine || submitting || !values} />
      </form>
    </>
  );
};

export default ClaseEditor;
