import { useState, useContext } from 'react';
import { AxiosError } from 'axios';
import { GeneralContext } from '../context/generalContext';
import { ClaseType, manageClasesProps } from '../types';
import { deleteClaseHelper, updateStudentsHelper } from '../helpers/clasesHelpers';
import {
  GetClases, DeleteClase, EditClase, CreateClase, AddNewStudent, RemoveOneStudent, DeleteAllStudents,
} from '../services/ClasesService';
import { UsersContext } from '../context/usersContext';

const UseClases = () => {
  const [clases, setClases] = useState<ClaseType[]>([]);
  const { manageUsers } = useContext(UsersContext);
  const { manageGeneral } = useContext(GeneralContext);

  const updateClases = () => (
    GetClases()
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: 200, action: 'updateAllClases' } });
        return setClases(data);
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'updateAllClases' } });
      })
  );

  const deleteClase = (token: string, claseId: string) => (
    DeleteClase(claseId, token)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'deleteClase' } });

        if (data.status === 200) {
          setClases(deleteClaseHelper(clases, claseId));
        }
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'deleteClase' } });
      })
  );

  const updateClase = (token: string, claseId: string, dataToUpdate: ClaseType) => (
    EditClase(token, claseId, dataToUpdate)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'updateClase' } });

        if (data.status === 200) {
          updateClases();
        }
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'updateOneClase' } });
      })
  );

  const CreateNewClase = (token: string, dataToUpdate: ClaseType) => (
    CreateClase(dataToUpdate, token)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'createClase' } });

        if (data.status === 201) {
          const newArr = clases.concat(data.clase);
          setClases(newArr);
        }
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'createClase' } });
      })
  );

  const addStudent = (claseId: string, token: string, studentId: string) => (
    AddNewStudent(claseId, token, studentId)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'addStudent' } });

        if (data.status === 200) {
          setClases(updateStudentsHelper(clases, { claseId, studentId, action: data.action }));
          return manageUsers({
            action: 'updateAbono', token, userId: studentId, abono: -1,
          });
        }

        return updateClases();
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'addStudent' } });
      })
  );

  const deleteSingleStudent = (claseId: string, token: string, studentId: string) => (
    RemoveOneStudent(claseId, token, studentId)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'deleteSingleStudent' } });

        if (data.status === 200) {
          setClases(updateStudentsHelper(clases, { claseId, studentId, action: data.action }));

          return manageUsers({
            action: 'updateAbono', token, userId: studentId, abono: 1,
          });
        }

        return updateClases();
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'deleteSingleStudent' } });
      })
  );

  const deleteAllStudents = (claseId: string, token: string) => (
    DeleteAllStudents(claseId, token)
      .then((data) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: data.status, action: 'deleteAllStudents' } });

        if (data.status === 200) {
          setClases(updateStudentsHelper(clases, { claseId, action: 'reset' }));
        } else {
          updateClases();
        }
      })
      .catch((err: AxiosError) => {
        manageGeneral({ action: 'setStatus', newStatus: { code: err.response ? err.response.status : 404, action: 'deleteAllStudents' } });
      })
  );

  const manageClases = (props: manageClasesProps) => {
    const {
      action, token, claseId, dataToUpdate, studentId,
    } = props;

    switch (action) {
      case 'create':
        return token && dataToUpdate && CreateNewClase(token, dataToUpdate);
      case 'delete':
        return token && claseId && deleteClase(token, claseId);
      case 'updateOne':
        return token && claseId && dataToUpdate && updateClase(claseId, token, dataToUpdate);
      case 'updateAll':
        return updateClases();
      case 'addStudent':
        return token && claseId && studentId && addStudent(claseId, token, studentId);
      case 'deleteSingleStudent':
        return token && claseId && studentId && deleteSingleStudent(claseId, token, studentId);
      case 'deleteAllStudents':
        return token && claseId && deleteAllStudents(claseId, token);
      default:
        return '';
    }
  };

  return {
    clases,
    manageClases,
  };
};

export default UseClases;
