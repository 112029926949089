import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const MusculacionPlain = (content: any) => {
  if (!content || !content.content || content.content.length === 0) {
    return <></>;
  }

  return (
    <div className="musc-wrapper">
      <i className="icon icon-musculacion" />
      <h2 className="musc-title">Musculación</h2>
      <div className="musc-description">
        {content.content && documentToReactComponents(content.content[0].fields.textoConFormato)}
      </div>
    </div>
  );
};

export default MusculacionPlain;
