import React, {
  FunctionComponent, useState, useEffect, useContext,
} from 'react';
import { ClaseType, ClaseCategoryType, ClaseTableProps } from '../types';
import OrderByTime from '../helpers/orderByTime';
import ClaseTableItem from './ClaseTableItem';
import { ClasesContext } from '../context/clasesContext';
import { AuthContext } from '../context/authContext';

const ClaseTable: FunctionComponent<ClaseTableProps> = ({ isAdminPanel }) => {
  const { clases } = useContext(ClasesContext);
  const { authUser } = useContext(AuthContext);
  const [clicked, setClicked] = useState('');

  const [filteredClases, setFilteredClases] = useState<ClaseType[] | undefined>();
  const isAdmin = Boolean(authUser && authUser.roles && authUser.roles.find((role: string) => role === 'Admin'));

  useEffect(() => {
    if (!isAdminPanel) {
      const noMuscClases = clases.filter((clase: ClaseType) => clase.category !== 'musculacion');
      setFilteredClases(noMuscClases.sort(OrderByTime));
    } else {
      setFilteredClases(clases.sort(OrderByTime));
    }
  }, [clases, isAdminPanel]);

  const filterCategory = (category: ClaseCategoryType) => {
    let orderedClases: ClaseType[] = [];
    setClicked(category);

    if (!isAdminPanel) {
      const noMuscClases = clases.filter((clase: ClaseType) => clase.category !== 'musculacion');
      orderedClases = noMuscClases.sort(OrderByTime);
    } else {
      orderedClases = clases;
    }

    if (category === '') {
      return setFilteredClases(orderedClases);
    }

    return setFilteredClases(orderedClases.filter((clase: ClaseType) => clase.category === category));
  };

  const today = new Date().getDay();
  const monday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'monday') : [];
  const tuesday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'tuesday') : [];
  const wednesday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'wednesday') : [];
  const thursday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'thursday') : [];
  const friday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'friday') : [];
  const saturday = filteredClases ? filteredClases.filter((clase: ClaseType) => clase.day === 'saturday') : [];

  return (
    <>
      {clases.length > 0
        && (
          <div className="filterButtons">
            <button
              className={clicked === '' ? 'buttonCategory selected' : 'buttonCategory'}
              tabIndex={0}
              type="button"
              onClick={() => filterCategory('')}
            >
              Todas
            </button>
            <button
              className={clicked === 'martial' ? 'buttonCategory selected martial' : 'buttonCategory martial'}
              tabIndex={0}
              type="button"
              onClick={() => filterCategory('martial')}
            >
              Artes Marciales
            </button>
            <button
              className={clicked === 'fitness' ? 'buttonCategory fitness selected' : 'buttonCategory fitness'}
              tabIndex={0}
              type="button"
              onClick={() => filterCategory('fitness')}
            >
              Fitness
            </button>
            <button
              className={clicked === 'indoor' ? 'buttonCategory indoor selected' : 'buttonCategory indoor'}
              tabIndex={0}
              type="button"
              onClick={() => filterCategory('indoor')}
            >
              Indoor
            </button>
            <button
              className={clicked === 'infantil' ? 'buttonCategory infantil selected' : 'buttonCategory infantil'}
              tabIndex={0}
              type="button"
              onClick={() => filterCategory('infantil')}
            >
              Infantil
            </button>
          </div>
        )}
      <div className={filteredClases && filteredClases.length === 0 ? 'noClases claseTableWrapper' : 'claseTableWrapper'}>
        {clases.length === 0 && <span className="helperText">No hay actividades disponibles en este momento</span>}
        {clases.length > 0
          && filteredClases
          && filteredClases.length === 0
          && <span className="helperText">No hay actividades en esta categoría</span>}
        {filteredClases && filteredClases.length > 0
          && (
            <>
              <div className="dayWrapper">
                <span className="dayTitle">Lunes</span>
                <div className="clasesDayWrapper">
                  {monday.length > 0 ? monday.map((clase: ClaseType) => (
                    <ClaseTableItem isDisabled={today > 1} clase={clase} isAdmin={isAdmin} key={clase._id} />
                  )) : <p className="helperText">No hay actividades este día.</p>}
                </div>
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Martes</span>
                <div className="clasesDayWrapper">
                  {tuesday.length > 0 ? tuesday.map((clase: ClaseType) => (
                    <ClaseTableItem
                      isDisabled={today > 2 || clase.students.length >= Number(clase.quota)}
                      clase={clase}
                      key={clase._id}
                      isAdmin={isAdmin}
                    />
                  )) : <p className="helperText">No hay actividades este día.</p>}
                </div>
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Miércoles</span>
                <div className="clasesDayWrapper">
                  {wednesday.length > 0 ? wednesday.map((clase: ClaseType) => (
                    <ClaseTableItem
                      isDisabled={today > 3 || clase.students.length >= Number(clase.quota)}
                      clase={clase}
                      key={clase._id}
                      isAdmin={isAdmin}
                    />
                  )) : <p className="helperText">No hay actividades este día.</p>}
                </div>
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Jueves</span>
                <div className="clasesDayWrapper">
                  {thursday.length > 0 ? thursday.map((clase: ClaseType) => (
                    <ClaseTableItem
                      clase={clase}
                      isDisabled={today > 4 || clase.students.length >= Number(clase.quota)}
                      key={clase._id}
                      isAdmin={isAdmin}
                    />
                  )) : <p className="helperText">No hay actividades este día.</p>}
                </div>
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Viernes</span>
                <div className="clasesDayWrapper">
                  {friday.length > 0 ? friday.map((clase: ClaseType) => (
                    <ClaseTableItem
                      clase={clase}
                      isDisabled={today > 5 || clase.students.length >= Number(clase.quota)}
                      key={clase._id}
                      isAdmin={isAdmin}
                    />
                  )) : <p className="helperText">No hay actividades este día.</p>}
                </div>
              </div>
              <div className="dayWrapper">
                <span className="dayTitle">Sábado</span>
                <div className="clasesDayWrapper">
                  {saturday.length > 0 ? saturday.map((clase: ClaseType) => (
                    <ClaseTableItem
                      clase={clase}
                      isDisabled={today > 6 || clase.students.length >= Number(clase.quota)}
                      key={clase._id}
                      isAdmin={isAdmin}
                    />
                  )) : <p className="helperText">No hay actividades este día.</p>}
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default ClaseTable;
