import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { GeneralContext } from '../context/generalContext';
import { AuthContext } from '../context/authContext';

const Verify = () => {
  const { general, manageGeneral } = useContext(GeneralContext);
  const [email, setEmail] = useState('');
  const [showForm, toggleShowForm] = useState(false);
  const { manageAuthUser } = useContext(AuthContext);

  const { status, loading } = general;

  if (status && status.action === 'token-not-exist') {
    toggleShowForm(true);
  }

  const resendEmail = () => {
    manageAuthUser({ action: 'resendVerificationEmail', email });
  };

  useEffect(() => {
    if (loading && status) {
      manageGeneral({ action: 'setLoading' });
    }
  }, [status, loading, manageGeneral]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    if (token) {
      return manageAuthUser({ action: 'verify', token });
    }

    toggleShowForm(true);
    return manageGeneral({
      action: 'setStatus',
      newStatus: {
        code: 400,
        action: 'verify',
        message: 'token-not-exist',
      },
    });
  }, []);

  return (
    <div id="confirmation" className="pageWrapper">
      {status && status.action === 'verify' && status.message === 'email-not-exist'
        && <p>El email ingresado no corresponde a ningún usuario registrado. por favor intentá nuevamente.</p>}

      {status && status.action === 'verify' && status.message === 'token-not-exist'
        && <p>No pudimos confirmar tu cuenta porque hubo un problema con tu email de confirmación. Para reenviarlo, completa tu email acá</p>}

      {showForm && status && status.action === 'verify' && (
        <>
          <input type="email" name="email" className="textInput" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <button onClick={resendEmail} type="button" className="btn small">Enviar</button>
        </>
      )}
      {status && (status.action === 'resendEmail' || status.action === 'verify') && status.code === 500
        && <p>Hubo un problema al reenviar el mail. Por favor contactate con nosotros para poder resolverlo.</p>}
      {status && status.action === 'resendEmail' && status.message === 'sent-success'
        && <p>El mail se reenvió correctamente a {email}. Por favor revisá tu bandeja de entrada y tu correo no deseado.</p>}
      {status && (status.action === 'resendEmail' || status.action === 'verify') && status.message === 'already-verified'
        && (
        <>
          <p>Tu email ya fue verificado.</p>
          <Link to="/login" className="btn" onClick={() => status && manageGeneral({ action: 'setStatus', newStatus: undefined })}>ingresar</Link>
        </>
        )}
      {status && (status.action === 'resendEmail' || status.action === 'verify') && status.code === 200
        && (
        <>
          <p>Tu email fue verificado exitosamente.</p>
          <Link to="/login" className="btn" onClick={() => status && manageGeneral({ action: 'setStatus', newStatus: undefined })}>ingresar</Link>
        </>
        )}
    </div>
  );
};

export default Verify;
