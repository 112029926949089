import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

type profe = {
  fields: {
    nombreCeo: string,
    instagramCeo: string,
    fotoCeo: {
      fields: {
        title?:string,
        file: {
          url: string
        }
      }
    }
  }
}

const CeosSection: React.FC<{content: profe[]}> = ({ content }) => {
  if (content.length === 0) {
    return <></>;
  }

  return (
    <div id="ceos" className="homeSection s-instagram">
      <a className="instagram-title" href="https://instagram.com/magnus_fight_and_fitness/">
        <i className="icon-musculacion icon" />
        <p className="sectionTitle">Ceos</p>
      </a>
      <div className="ceosContainer">
        {
          content && content.map(({ fields: { nombreCeo, instagramCeo, fotoCeo: { fields: { file: { url } } } } }) => (
            url && instagramCeo ? (
              <a href={instagramCeo} key={nombreCeo}>
                <div className="ceoWrapper">
                  <img
                    className="ceoImage"
                    src={url}
                    alt={nombreCeo}
                  />
                  {nombreCeo && <span>{nombreCeo} </span>}
                </div>
              </a>
            ) : <></>
          ))
        }
      </div>
    </div>
  );
};

export default CeosSection;
