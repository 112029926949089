import React, { createContext } from 'react';
import UseAuth from '../hooks/authHook';
import { authContext } from '../types';

export const AuthContext = createContext({} as authContext);

export const AuthProvider = ({ children }: any) => {
  const { authUser, manageAuthUser } = UseAuth();

  return <AuthContext.Provider value={{ authUser, manageAuthUser }}>{children}</AuthContext.Provider>;
};
