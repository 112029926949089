import React, { useState, useContext } from 'react';
import ReactModal from 'react-modal';
import { UserType, ClaseType } from '../types';
import { GeneralContext } from '../context/generalContext';
import Loader from '../components/Loading';
import { ClasesContext } from '../context/clasesContext';
import { UsersContext } from '../context/usersContext';
import { AuthContext } from '../context/authContext';
import { days } from '../helpers/consts';

const AdminUserRow = (user: UserType, key: string) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [abono, setAbono] = useState<number>(0);
  const { authUser } = useContext(AuthContext);
  const { clases, manageClases } = useContext(ClasesContext);
  const { manageUsers } = useContext(UsersContext);
  const { general, manageGeneral } = useContext(GeneralContext);
  const { loading, status } = general;

  const {
    firstName, lastName, gymId, _id, abonoRemaining, email, isVerified,
  } = user;

  const toggleModal = () => {
    if (status) { manageGeneral({ action: 'setStatus', newStatus: undefined }); }
    setModalOpen(!isModalOpen);
  };

  const onChange = (e: any) => setAbono(e.target.value);

  const sendUpdateAbono = () => {
    if (!abono || abono === 0 || !_id) {
      return;
    }

    manageGeneral({ action: 'setLoading' });
    manageUsers({
      action: 'updateAbono',
      abono,
      userId: _id,
      token: authUser.token,
    });
  };

  const RemoveStudent = (studentId: string, claseId: string) => {
    manageGeneral({ action: 'setLoading' });
    manageClases({
      action: 'deleteSingleStudent', claseId, token: authUser.token, studentId,
    });
  };

  const RemoveUser = (userId: string) => {
    manageGeneral({ action: 'setLoading' });
    manageUsers({ action: 'deleteUser', userId, token: authUser.token });
  };

  const VerifyUser = (userId: string) => {
    manageGeneral({ action: 'setLoading' });
    manageUsers({ action: 'activateUser', userId, token: authUser.token });
  };

  const reducer = (total: ClaseType[], current: ClaseType) => {
    if (current._id && current.students.find((student) => student === _id) && !total.find((clase) => clase._id === current._id)) {
      return total.concat(current);
    }

    return total;
  };

  const userClasses = clases.reduce(reducer, []);

  const generateClases = (verifiedStatus: boolean, abonoQuantity: number) => {
    let finalCssClasses = 'userList';

    if (!verifiedStatus) {
      finalCssClasses = finalCssClasses.concat(' notVerified');
    }

    if (verifiedStatus && abonoQuantity <= 2) {
      finalCssClasses = finalCssClasses.concat(' lowAbono');
    }

    return finalCssClasses;
  };

  return (
    <>
      <li className={generateClases(isVerified, abonoRemaining)} key={key}>
        <span className="userListName">{firstName} {lastName} - ID N° {gymId}</span>
        <button type="button" onClick={toggleModal} className="btn small">ver más</button>
      </li>
      <ReactModal
        isOpen={isModalOpen}
        contentLabel="User Modal"
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick
        className="modal"
        overlayClassName="overlay"
        ariaHideApp={false}
      >
        <i className="icon icon-close" role="button" tabIndex={0} onClick={toggleModal} />
        {loading && <Loader isModal />}
        <p className="userDataTitle">Datos de Usuario</p>
        {status && status.action === 'deleteUser' && status.code === 200
          ? <p className="helperText">Usuario eliminado exitosamente.</p>
          : (
            <>
              <div className="actionsWrapper">
                <button type="button" className="btn small" onClick={() => _id && RemoveUser(_id)}>Eliminar Usuario</button>
                {!isVerified && <button type="button" className="btn small" onClick={() => _id && VerifyUser(_id)}>Activar USuario</button>}
              </div>
              <div className="userDataWrapper">
                <p><span className="userDataLabel">Nombre y apellido:</span> {firstName} {lastName}</p>
                <p><span className="userDataLabel">ID del gimnasio:</span> #{gymId}</p>
                <p><span className="userDataLabel">Email:</span> {email}</p>
                <p><span className="userDataLabel">Clases disponibles:</span> {abonoRemaining}</p>
                <div className="claseContainer">
                  <p className="userDataLabel">Clases en las que está anotado:</p>
                  {
                    (userClasses.length < 1)
                      ? <span className="noClasses">No está inscripto a ninguna clase.</span>
                      : userClasses.map((clase: ClaseType) => (
                        <div className="claseItemRowWrapper" key={clase._id}>
                          <p className="claseItem">{clase.name} el {days[clase.day]} a las {clase.startTime}</p>
                          <button type="button" className="btn small" onClick={() => _id && clase._id && RemoveStudent(_id, clase._id)}>
                            <i className="icon icon-delete" />
                          </button>
                        </div>
                      ))
                  }
                </div>
              </div>
              <form className="addAbonoWrapper">
                <span className="abonoTitle">Aumentar o disminuir pack</span>
                <div className="inputWrapper">
                  <input className="textInput" type="number" onChange={onChange} />
                  <button type="button" className="btn small" onClick={sendUpdateAbono}>guardar</button>

                  {status && status.action === 'updateUserAbono' && status.code !== 200
                    && <p className="errorText">Algo salió mal. Por favor intentá de nuevo en unos minutos.</p>}
                  {status && status.action === 'updateUserAbono' && status.code === 200
                    && <p className="helperText">Usuario actualizado exitosamente.</p>}
                </div>
              </form>
            </>
          )}
      </ReactModal>
    </>
  );
};

export default AdminUserRow;
