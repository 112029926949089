import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import jwt_decode from 'jwt-decode';
import { GeneralContext } from '../context/generalContext';
import ResetPasswordForm from '../components/ResetPasswordForm';
import { AuthContext } from '../context/authContext';

const ResetPassword = () => {
  const { general, manageGeneral } = useContext(GeneralContext);
  const { manageAuthUser } = useContext(AuthContext);
  const { status, loading } = general;

  useEffect(() => {
    if (loading) {
      manageGeneral({ action: 'setLoading' });
    }
  }, []);

  useEffect(() => {
    if (status && status.action === 'resetPassword' && loading) {
      manageGeneral({ action: 'setLoading' });
    }
  }, [status, loading, manageGeneral]);

  const ChangePassword = (password: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    const decoded: any = token && jwt_decode(token);

    manageGeneral({ action: 'setLoading' });
    if (token && decoded.userId) {
      return manageAuthUser({
        action: 'resetPassword',
        password,
        token,
        userId: decoded.userId,
      });
    }

    return manageGeneral({ action: 'setStatus', newStatus: { code: 500, action: 'resetPassword' } });
  };

  return (
    <div className="pageWrapper" id="password">
      <h1 className="title">Renovar contraseña</h1>
      {status && status.code !== 202 && status.action === 'resetPassword'
        && (
          <span className="errorText"> Hubo un problema. Por favor intentá nuevamente. </span>
        )}
      {status && status.code === 202 && status.action === 'resetPassword'
        && (
          <>
            <i className="icon icon-heart" />
            <p className="title">¡Gracias!</p>
            <p className="subtitle">Tu contraseña fue actualizada exitosamente, ya la podés usar para ingresar.</p>
            <Link className="btn" to="/login" onClick={() => status && manageGeneral({ action: 'setStatus', newStatus: undefined })}>Ingresar</Link>
          </>
        )}
      {((status && status.action !== 'resetPassword') || !status) && <ResetPasswordForm onFormSubmit={ChangePassword} />}
    </div>
  );
};

export default ResetPassword;
