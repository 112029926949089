import { ClaseType } from '../types';

const OrderByTime = (a: ClaseType, b: ClaseType) => {
  const timeA = a.startTime;
  const timeB = b.startTime;

  let comparison = 0;

  if (timeA > timeB) {
    comparison = 1;
  } else if (timeA < timeB) {
    comparison = -1;
  }

  return comparison;
};

export default OrderByTime;
