import { UserType } from '../types';

const OrderByGymId = (a: UserType, b: UserType) => {
  let comparison = 0;

  if (a.gymId > b.gymId) {
    comparison = 1;
  } else if (a < b) {
    comparison = -1;
  }

  return comparison;
};

export default OrderByGymId;
