import { contentfulClient } from './ApiClient';
import { contentfulObj } from '../types';

export const contentfulService = () => {
  const promise = contentfulClient.getEntries()
    .then((entries: any) => entries.items);

  const dataPromise = promise.then((response: any[]) => {
    const formattedEntries: contentfulObj[] = [];

    response.map((entry: any) => formattedEntries.push({
      fields: entry.fields,
      contentType: entry.sys.contentType.sys.id,
    }));

    return formattedEntries;
  });

  return dataPromise;
};

export const test = '';
