import { ApiClient } from './ApiClient';
import {
  ClaseType, apiResponse, UpdateClaseResponse, UpdateStudentsResponse, CreateClaseResponse,
} from '../types';

export const GetClases = () => {
  const promise = ApiClient.get<apiResponse>('/clases/');
  const dataPromise = promise.then((response) => response.data.clases);
  return dataPromise;
};

export const DeleteClase = (claseId: string, token: string) => {
  const promise = ApiClient.delete<Partial<UpdateClaseResponse>>(`/clases/${claseId}`, { headers: { Authorization: `Bearer ${token}` } });

  const dataPromise = promise.then((response) => ({
    status: response.status,
    claseId,
  }));

  return dataPromise;
};

export const EditClase = (claseId: string, token: string, data: ClaseType) => {
  const promise = ApiClient.patch<Partial<UpdateClaseResponse>>(`/clases/${claseId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise: Promise<UpdateClaseResponse> = promise.then((response) => ({
    status: response.status,
    claseId,
  }));

  return dataPromise;
};

export const CreateClase = (newClase: ClaseType, token: string) => {
  const promise = ApiClient.post<any>('/clases/', newClase, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise: Promise<CreateClaseResponse> = promise.then((response) => ({
    status: response.status,
    clase: response.data.createdClase,
  }));

  return dataPromise;
};

export const AddNewStudent = (claseId: string, token: string, studentId: string) => {
  const promise = ApiClient.post<Partial<UpdateStudentsResponse>>(`/clases/addStudent/${claseId}`, { student: studentId }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise = promise.then((response) => ({
    claseId, action: 'add', status: response.status, studentId,
  }));

  return dataPromise;
};

export const RemoveOneStudent = (claseId: string, token: string, studentId: string) => {
  const promise = ApiClient.post<Partial<UpdateStudentsResponse>>(`/clases/removeStudent/${claseId}`, { student: studentId }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise = promise.then((response) => ({
    claseId, action: 'remove', studentId, status: response.status,
  }));

  return dataPromise;
};

export const DeleteAllStudents = (claseId: string, token: string) => {
  const promise = ApiClient.post<Partial<UpdateStudentsResponse>>(`/clases/removeAllStudents/${claseId}`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise = promise.then((response) => ({ claseId, action: 'reset', status: response.status }));

  return dataPromise;
};
