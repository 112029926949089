import { ApiClient } from './ApiClient';
import {
  loginResponse, statusResponse, UserType, usersApiResponse, usersStatusApiResponse,
} from '../types';

export const isUserAuthenticated = () => {
  if (localStorage.getItem('userToken')) {
    return true;
  }

  return false;
};

export const signUp = (newUser: UserType) => {
  const promise = ApiClient.post<statusResponse>('/users/signup', newUser);

  const dataPromise = promise.then((response) => ({
    status: response.status,
  }));

  return dataPromise;
};

export const LogIn = (credentials: Pick<UserType, 'email' | 'password'>) => {
  const promise = ApiClient.post<loginResponse>('/users/login', credentials);

  const dataPromise = promise.then((response) => ({
    data: response.data, status: response.status,
  }));

  return dataPromise;
};

export const GetUsers = (token: string) => {
  const promise = ApiClient.get<usersApiResponse>('/users/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise = promise.then((response) => response.data.users);
  return dataPromise;
};

export const GetSingleUser = (token: string, userId: string) => {
  const promise = ApiClient.get<any>(`/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise = promise.then((response) => ({
    data: response.data, status: response.status,
  }));

  return dataPromise;
};

export const verifyEmail = (token: string) => {
  const promise = ApiClient.get<statusResponse>(`users/confirmation/${token}`);

  const dataPromise = promise.then((response) => ({
    status: response.status,
  }));

  return dataPromise;
};

export const resendConfirmation = (email: string) => {
  const promise = ApiClient.post<any>('users/resend', { email });

  const dataPromise = promise.then((response) => ({
    status: response.status,
    message: response.data.type,
  }));

  return dataPromise;
};

export const UpdateAbono = (token: string, userId: string, abono: number) => {
  const promise = ApiClient.post<usersStatusApiResponse>(`/users/abonoUpdate/${userId}`, { abonoToAdd: abono }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise = promise.then((response) => ({
    status: response.status,
  }));

  return dataPromise;
};

export const DeleteUser = async (userId: string, token: string) => {
  const promise = ApiClient.delete<usersStatusApiResponse>(`/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise = promise.then((response) => ({
    status: response.status,
  }));

  return dataPromise;
};

export const ActivateUser = async (userId: string, token: string) => {
  const promise = ApiClient.get<usersStatusApiResponse>(`/users/confirmation/admin/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const dataPromise = promise.then((response) => ({
    status: response.status,
  }));

  return dataPromise;
};

export const ForgotPasswordReq = (email: string) => {
  const promise = ApiClient.post<statusResponse>(`users/pw-reset/${email}`);

  const dataPromise = promise.then((response) => ({
    status: response.status,
  }));

  return dataPromise;
};

export const ResetPasswordReq = (password: string, token: string, userId: string) => {
  const promise = ApiClient.post<statusResponse>(`/users/new-pw/${userId}`, { password, token });

  const dataPromise = promise.then((response) => ({
    status: response.status,
  }));

  return dataPromise;
};
