import React, { useState, useContext, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { HashLink as NavLink } from 'react-router-hash-link';
import { AuthContext } from '../context/authContext';
import { GeneralContext } from '../context/generalContext';

const Navigation = () => {
  const [menuOpen, toggleMenu] = useState(false);
  const toggleMobileMenu = () => toggleMenu(!menuOpen);
  const { authUser, manageAuthUser } = useContext(AuthContext);
  const { general, manageGeneral } = useContext(GeneralContext);
  const { status, loading } = general;

  useEffect(() => {
    if (status && status.action === 'logout' && loading) {
      manageGeneral({ action: 'setLoading' });
      manageGeneral({ action: 'setStatus', newStatus: undefined });
    }
  }, [status]);

  const isAuth = authUser && authUser.token && authUser.token.length > 0;

  const AuthButton = withRouter(({ history }) => {
    const signOut = () => {
      toggleMobileMenu();
      manageGeneral({ action: 'setLoading' });
      manageAuthUser({ action: 'logout' });
      window.localStorage.clear();
      return history.push('/');
    };

    return isAuth ? (
      <>
        <span className="drawer-link">¡Hola, {authUser.firstName}!</span>
        <button type="button" className="btn small" onClick={() => signOut()}>
          Salir
        </button>
        <hr className="drawer-divider" />
      </>
    ) : (
      <>
        <div
          className="drawer-linkWrapper"
          role="button"
          tabIndex={0}
          onClick={toggleMobileMenu}
        >
          <Link className="drawer-link" to="/login">
            Ingresar
          </Link>
        </div>
        <hr className="drawer-divider" />
        <div
          className="drawer-linkWrapper"
          role="button"
          tabIndex={0}
          onClick={toggleMobileMenu}
        >
          <Link className="btn small" to="/registro">
            Registrarse
          </Link>
        </div>
        <hr className="drawer-divider" />
      </>
    );
  });

  const AuthButtonMobile = withRouter(({ history }) => {
    const signOut = () => {
      toggleMobileMenu();
      manageAuthUser({ action: 'logout' });
      window.localStorage.clear();
      return history.push('/');
    };

    return isAuth ? (
      <>
        <div
          className="drawer-linkWrapper"
          role="button"
          tabIndex={0}
          onClick={toggleMobileMenu}
        >
          <button
            type="button"
            className="drawer-link"
            onClick={() => signOut()}
          >
            Salir
          </button>
        </div>
        <hr className="drawer-divider" />
      </>
    ) : (
      <>
        <div
          className="drawer-linkWrapper"
          role="button"
          tabIndex={0}
          onClick={toggleMobileMenu}
        >
          <Link className="drawer-link" to="/login">
            Ingresar
          </Link>
        </div>
        <hr className="drawer-divider" />
        <div
          className="drawer-linkWrapper"
          role="button"
          tabIndex={0}
          onClick={toggleMobileMenu}
        >
          <Link className="drawer-link" to="/registro">
            Registrarse
          </Link>
        </div>
        <hr className="drawer-divider" />
      </>
    );
  });

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item logo-item">
            <div className="logo" />
          </Link>
          <div className="navbar-item redes-item">
            <a
              href="https://www.facebook.com/MagnusFightFitness/"
              role="button"
              tabIndex={0}
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="icon icon-facebook" />
            </a>
            <a
              href="https://instagram.com/magnusfightfitness/"
              role="button"
              tabIndex={0}
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="icon icon-instagram" />
            </a>
          </div>
          <div className="navbar-links-wrapper">
            <AuthButton />
          </div>
          <button
            type="button"
            tabIndex={0}
            onClick={toggleMobileMenu}
            className={
              menuOpen
                ? 'is-active icon-close navbar-burger-menu'
                : ' icon-burger navbar-burger-menu'
            }
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          />
        </div>
      </nav>
      <div className="header">
        <div className="logo" />
        <div className="header-linkWrapper">
          <Link className="drawer-link" to="/">
            Home
          </Link>
          <NavLink className="drawer-link" to="/#clases">
            Horarios
          </NavLink>
          {authUser.token && authUser.roles && authUser.roles[0] === 'Admin' && (
            <Link className="drawer-link" to="/admin">
              Panel Administrador
            </Link>
          )}
          {authUser.token && authUser.roles && authUser.roles[0] === 'User' && (
            <Link className="drawer-link" to="/perfil">
              Mi perfil
            </Link>
          )}
          <NavLink className="drawer-link" to="/#footer">
            Contacto
          </NavLink>
        </div>
      </div>
      <div className={menuOpen ? 'drawer active' : 'drawer'}>
        <div className="drawer-links">
          <div className="drawer-wrapper">
            <div
              className="drawer-linkWrapper"
              role="button"
              tabIndex={0}
              onClick={toggleMobileMenu}
            >
              <NavLink className="drawer-link" to="/">
                Home
              </NavLink>
            </div>
            <hr className="drawer-divider" />
            <AuthButtonMobile />
            <div
              className="drawer-linkWrapper"
              role="button"
              tabIndex={0}
              onClick={toggleMobileMenu}
            >
              <NavLink className="drawer-link" to="/#horarios">
                Horarios
              </NavLink>
            </div>
            <hr className="drawer-divider" />
            {authUser.token && authUser.roles && authUser.roles[0] === 'Admin' && (
              <>
                <div
                  className="drawer-linkWrapper"
                  role="button"
                  tabIndex={0}
                  onClick={toggleMobileMenu}
                >
                  <Link className="drawer-link" to="/admin">
                    Panel Administrador
                  </Link>
                </div>
                <hr className="drawer-divider" />
              </>
            )}
            {authUser.token && authUser.roles && authUser.roles[0] === 'User' && (
              <>
                <div
                  className="drawer-linkWrapper"
                  role="button"
                  tabIndex={0}
                  onClick={toggleMobileMenu}
                >
                  <Link className="drawer-link" to="/perfil">
                    Mi perfil
                  </Link>
                </div>
                <hr className="drawer-divider" />
              </>
            )}
            <div
              className="drawer-linkWrapper"
              role="button"
              tabIndex={0}
              onClick={toggleMobileMenu}
            >
              <NavLink className="drawer-link" to="/#footer">
                Contacto
              </NavLink>
            </div>
          </div>
          <div
            className={menuOpen ? 'drawer-overlay active' : 'drawer-overlay'}
            role="button"
            tabIndex={0}
            onClick={toggleMobileMenu}
          />
        </div>
      </div>
    </>
  );
};

export default Navigation;
