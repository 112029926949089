import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const { authUser } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => (
        authUser.token
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )}
    />
  );
};

export default ProtectedRoute;
