import { useState } from 'react';
import { AxiosError } from 'axios';

import { statusType, generalState, useGeneralProps } from '../types';
import { contentfulService } from '../services/contentService';

const UseGeneral = () => {
  const [general, setGeneral] = useState({ status: undefined, loading: true, content: {} } as generalState);

  const toggleLoading = () => setGeneral(((prevState) => ({ ...prevState, loading: !prevState.loading })));

  const updateStatus = (newStatus?: statusType) => {
    setGeneral(((prevState) => ({ ...prevState, status: newStatus })));
  };

  const getDataFromContentful = () => {
    contentfulService()
      .then((data: any) => {
        const preparedData: any = {};

        data.map((item: any) => {
          if (preparedData[item.contentType]) {
            return preparedData[item.contentType].push(item);
          }

          preparedData[item.contentType] = [item];
          return null;
        });

        setGeneral(((prevState) => ({ ...prevState, content: preparedData })));
      })
      .catch((err: AxiosError) => {
        updateStatus({ code: err.response ? err.response.status : 404, action: 'deleteSingleStudent' });
      });
  };

  const manageGeneral = (props: useGeneralProps) => {
    const { action, newStatus } = props;
    switch (action) {
      case 'setLoading':
        return toggleLoading();
      case 'setStatus':
        return updateStatus(newStatus);
      case 'getDataFromContentful':
        return getDataFromContentful();
      default:
        return '';
    }
  };

  return {
    general,
    manageGeneral,
  };
};

export default UseGeneral;
