import { ClaseType, clasePayloadType, IsClasePresentType } from '../types';

export const deleteClaseHelper = (clases: ClaseType[], claseToDelete: string) => {
  const filteredClases = clases.filter((clase) => clase._id !== claseToDelete);
  return filteredClases;
};

export const updateStudentsHelper = (clases: ClaseType[], payload: clasePayloadType) => {
  const { claseId, studentId = '', action } = payload;
  const claseToEdit = clases.findIndex((clase: ClaseType) => clase._id === claseId);
  const tempClases = clases;

  switch (action) {
    case 'add': {
      tempClases[claseToEdit].students.push(studentId);
      break;
    }
    case 'remove': {
      tempClases[claseToEdit].students = clases[claseToEdit].students.filter((student) => student !== studentId);
      break;
    }
    case 'reset': {
      tempClases[claseToEdit].students = [];
      break;
    }
    default:
      break;
  }

  return tempClases;
};

export const isClasePresent: IsClasePresentType = (clases, currentClase) => Boolean(
  clases.find((clase) => clase.name === currentClase.name
    && clase.teacher === currentClase.teacher
    && clase.startTime === currentClase.startTime
    && clase.endTime === currentClase.endTime
    && clase.day === currentClase.day
    && clase.category === currentClase.category),
);
