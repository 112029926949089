import React, { FunctionComponent } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import {
  ClaseType, ClaseCreatorType, claseCreatorForm, ErrorType,
} from '../types';
import { numberRegex } from '../helpers/validators';
import { hours, minutes } from '../helpers/consts';

const ClaseCreator: FunctionComponent<ClaseCreatorType> = ({ onFormSubmit, clasePresent }) => {
  const onSubmit = (values: claseCreatorForm) => {
    const clase: ClaseType = {
      name: values.name,
      teacher: values.teacher,
      startTime: `${values.startTimeHr}:${values.startTimeMin}`,
      endTime: `${values.endTimeHr}:${values.endTimeMin}`,
      day: values.day,
      quota: values.quota,
      category: values.category,
      students: [],
    };

    onFormSubmit(clase);
  };

  const validate = (values: claseCreatorForm) => {
    const {
      name, teacher, startTimeHr, startTimeMin, endTimeHr, endTimeMin, quota, day, category,
    } = values;

    const errors: ErrorType = {};

    if (!name) {
      errors.name = 'Campo obligatorio';
    }
    if (!teacher) {
      errors.teacher = 'Campo obligatorio';
    }
    if (!startTimeHr) {
      errors.startTimeHr = 'Campo obligatorio';
    }
    if (!startTimeMin) {
      errors.startTimeMin = 'Campo obligatorio';
    }
    if (!endTimeHr) {
      errors.endTimeHr = 'Campo obligatorio';
    }
    if (!endTimeMin) {
      errors.endTimeMin = 'Campo obligatorio';
    }
    if (Number(startTimeHr) > Number(endTimeHr)) {
      errors.startTimeHr = 'la hora de comienzo debe ser menor que la de fin';
      errors.endTimeHr = 'la hora de comienzo debe ser menor que la de fin';
    } else if (Number(startTimeHr) === Number(endTimeHr)) {
      if (Number(startTimeMin) > Number(endTimeMin) || Number(startTimeMin) === Number(endTimeMin)) {
        errors.startTimeHr = 'la hora de comienzo debe ser menor que la de fin';
        errors.endTimeHr = 'la hora de comienzo debe ser menor que la de fin';
      }
    }
    if (!quota) {
      errors.quota = 'Campo obligatorio';
    } else if (!numberRegex.test(quota)) {
      errors.quota = 'El cupo debe tener solo digitos numéricos';
    }
    if (!day) {
      errors.day = 'Campo obligatorio';
    }
    if (!category) {
      errors.category = 'Campo obligatorio';
    }
    return errors;
  };

  const {
    form, handleSubmit, values, pristine, submitting,
  } = useForm({
    onSubmit,
    validate,
    initialValues: {
      name: '',
      teacher: '',
      startTimeHr: '08',
      startTimeMin: '00',
      endTimeHr: '08',
      endTimeMin: '05',
      day: '',
      quota: '',
      category: '',
    },
  });

  const name = useField('name', form);
  const teacher = useField('teacher', form);
  const quota = useField('quota', form);
  const day = useField('day', form);
  const category = useField('category', form);
  const startTimeHr = useField('startTimeHr', form);
  const startTimeMin = useField('startTimeMin', form);
  const endTimeMin = useField('endTimeMin', form);
  const endTimeHr = useField('endTimeHr', form);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="formColumns">
          <div className="inputWrapper">
            <input className="textInput" type="text" {...name.input} placeholder="Clase" />
            {name.meta.touched && name.meta.error && (
              <span className="errorText">{name.meta.error}</span>
            )}
          </div>
          <div className="inputWrapper">
            <input className="textInput" type="text" {...teacher.input} placeholder="Instructor" />
            {teacher.meta.touched && teacher.meta.error && (
              <span className="errorText">{teacher.meta.error}</span>
            )}
          </div>
        </div>
        <div className="formColumns">
          <div className="smallSelectWrapper">
            <span className="selectLabel">Hora de comienzo de la clase</span>
            <div className="inputWrapper">
              <div className="arrow">▼</div>
              <select className="select" {...startTimeHr.input}>
                {hours.map((hour: string) => <option className="selectOption" key={hour} value={hour}>{hour}</option>)}
              </select>
            </div>
            <div className="inputWrapper">
              <div className="arrow">▼</div>
              <select className="select" {...startTimeMin.input}>
                {minutes.map((minute: string) => <option className="selectOption" key={minute} value={minute}>{minute}</option>)}
              </select>
            </div>
            {startTimeHr.meta.error && (
              <span className="errorText">{startTimeHr.meta.error}</span>
            )}
          </div>
          <div className="smallSelectWrapper">
            <span className="selectLabel">Hora de fin de la clase</span>
            <div className="inputWrapper">
              <div className="arrow">▼</div>
              <select className="select" {...endTimeHr.input}>
                {hours.map((hour: string) => <option className="selectOption" key={hour} value={hour}>{hour}</option>)}
              </select>
            </div>
            <div className="inputWrapper">
              <div className="arrow">▼</div>
              <select className="select" {...endTimeMin.input}>
                {minutes.map((minute: string) => <option className="selectOption" key={minute} value={minute}>{minute}</option>)}
              </select>
            </div>
            { endTimeHr.meta.error && (
              <span className="errorText">{endTimeHr.meta.error}</span>
            )}
          </div>
        </div>
        <div className="formColumns three">
          <div className="inputWrapper">
            <input className="textInput" type="text" {...quota.input} placeholder="Cupo" />
            {quota.meta.touched && quota.meta.error && (
              <span className="errorText">{quota.meta.error}</span>
            )}
          </div>
          <div className="inputWrapper">
            <div className="arrow">▼</div>
            <select className="select" {...day.input}>
              <option className="selectOption" value="">Elegí un día</option>
              <option className="selectOption" value="monday">Lunes</option>
              <option className="selectOption" value="tuesday">Martes</option>
              <option className="selectOption" value="wednesday">Miercoles</option>
              <option className="selectOption" value="thursday">Jueves</option>
              <option className="selectOption" value="friday">Viernes</option>
              <option className="selectOption" value="saturday">Sabado</option>
            </select>
            {day.meta.touched && day.meta.error && (
              <span className="errorText">{day.meta.error}</span>
            )}
          </div>
          <div className="inputWrapper">
            <div className="arrow">▼</div>
            <select className="select" {...category.input}>
              <option className="selectOption" value="">Elegir categoría</option>
              <option className="selectOption" value="martial">Artes Marciales</option>
              <option className="selectOption" value="fitness">Fitness</option>
              <option className="selectOption" value="musculacion">Musculacion</option>
              <option className="selectOption" value="indoor">Indoor</option>
              <option className="selectOption" value="infantil">Infantil</option>
            </select>
            {category.meta.touched && category.meta.error && (
              <span className="errorText">{category.meta.error}</span>
            )}
          </div>
        </div>
        <input type="submit" className="btn" value="Agregar Clase" disabled={pristine || submitting || !values} />
        {clasePresent && <span className="errorText">Esta clase ya existe</span>}
      </form>
    </>
  );
};

export default ClaseCreator;
