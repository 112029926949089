import React, { useEffect, useContext } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { GeneralContext } from '../context/generalContext';
import { AuthContext } from '../context/authContext';
import LogInForm from '../components/LogInForm';
import { UserType } from '../types';

const LogInPage = () => {
  const { manageAuthUser } = useContext(AuthContext);
  const { general, manageGeneral } = useContext(GeneralContext);

  // @ts-ignore
  const { from } = useLocation().state || { from: { pathname: '/' } };
  const { status, loading } = general;

  useEffect(() => {
    if (loading) {
      manageGeneral({ action: 'setLoading' });
    }
  }, []);

  useEffect(() => {
    if (status && status.action === 'login' && loading) {
      manageGeneral({ action: 'setLoading' });
    }
  }, [status, loading, manageGeneral]);

  const login = (user: Pick<UserType, 'email' | 'password'>) => {
    manageGeneral({ action: 'setLoading' });
    manageAuthUser({ action: 'login', loginUser: user });
  };

  if (status && status.code === 200 && status.action === 'login') {
    manageGeneral({ action: 'setStatus', newStatus: undefined });
    return <Redirect to={from} />;
  }

  return (
    <div className="pageWrapper">
      <h1 className="title">Ingresar</h1>
      {status && status.action === 'login'
        && (
          <span className="errorText">
            {status.code === 401 && status.message !== 'not-verified' && 'Los datos ingresados no son correctos. Por favor intentá nuevamente.' }
            { status.message && status.message === 'not-verified'
              && 'Este cuenta no ha sido verificada todavía. Por favor revisá tu correo, te enviamos un email con instrucciones.'}
            {
              status.code !== 401
              && status.message
              && status.message !== 'not-verified'
              && 'Hubo un problema con la conexión, por favor intentá de nuevo en unos minutos.'
            }
          </span>
        )}
      <LogInForm onFormSubmit={login} />
    </div>
  );
};

export default LogInPage;
