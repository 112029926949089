import React, { createContext } from 'react';
import UseClases from '../hooks/clasesHook';
import { clasesContextType } from '../types';

export const ClasesContext = createContext({} as clasesContextType);

export const ClasesProvider = ({ children }: any) => {
  const { clases, manageClases } = UseClases();

  return <ClasesContext.Provider value={{ clases, manageClases }}>{children}</ClasesContext.Provider>;
};
