import React, { useEffect, useState, useContext } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { isUserAuthenticated } from '../services/UsersService';
import { SendContactForm } from '../services/generalService';
import { contactFormType } from '../types';
import ConstructionModal from '../components/Construccion';
import ContactForm from '../components/ContactForm';
import ClaseMobileHome from '../components/ClaseMobileHome';
import MusculacionPlain from '../components/MusculacionPlain';
import CeosSection from '../components/CeosSection';
import InstagramSection from '../components/InstagramSection';
import { ClasesContext } from '../context/clasesContext';
import { UsersContext } from '../context/usersContext';
import { GeneralContext } from '../context/generalContext';
import { AuthContext } from '../context/authContext';
import useWindowSize from '../helpers/useWindowSize';
import ClaseTable from '../components/ClaseTable';
import banner from '../assets/noslider.jpg';

const HomePage = () => {
  const [formStatus, setFormStatus] = useState<number | undefined>();
  const size = useWindowSize();
  const { clases, manageClases } = useContext(ClasesContext);
  const { manageUsers } = useContext(UsersContext);
  const { authUser, manageAuthUser } = useContext(AuthContext);
  const { general, manageGeneral } = useContext(GeneralContext);
  const {
    loading,
    status,
    content: {
      homepageBanner, footer, instagramPost, musculacion, ceos,
    },
  } = general;

  const contactFormCallback = (newstatus: number) => {
    setFormStatus(newstatus);
    setTimeout(() => setFormStatus(undefined), 15000);
  };

  const contactFormSubmit = (values: contactFormType) => {
    setFormStatus(100);
    SendContactForm(values, contactFormCallback);
  };

  useEffect(() => {
    manageGeneral({ action: 'getDataFromContentful' });
  }, []);

  useEffect(() => {
    const isReturning = isUserAuthenticated();

    if (
      isReturning
      && !authUser._id
      && (!status || (status && status.action === 'login'))
    ) {
      const currentUser = {
        token: window.localStorage.getItem('userToken') || '',
        _id: window.localStorage.getItem('userId') || '',
      };

      manageAuthUser({
        action: 'getSingleUser',
        token: currentUser.token,
        userId: currentUser._id,
      });
      manageUsers({ action: 'updateUsers', token: currentUser.token });
      manageClases({ action: 'updateAll' });

      return;
    }

    if (
      (!clases || clases.length <= 0)
      && (!status || (status && status.action === 'login'))
    ) {
      manageClases({ action: 'updateAll' });
    }

    if (status && loading) {
      if (
        status.action === 'updateAllClases'
        || status.action === 'updateUserAbono'
        || status.action === 'deleteClase'
        || status.action === 'deleteAllStudents'
      ) {
        manageGeneral({ action: 'setLoading' });
      }
    }
  }, [status]);

  return (
    <div className="pageWrapper home">
      {/* <ConstructionModal /> */}
      <div className="homeSection s-banner">
        {general.content && homepageBanner && (
          <img
            className="heroBanner"
            alt="banner"
            src={homepageBanner[0].fields.imagen.fields.file.url}
          />
        )}
      </div>
      <div id="clases" className="homeSection s-clases">
        <MusculacionPlain
          content={general.content && musculacion ? musculacion : []}
        />
        <p className="sectionTitle">Actividades y horarios</p>
        {size.width && size.width > 1024 ? (
          <ClaseTable isAdminPanel={false} />
        ) : (
          <ClaseMobileHome isAdminPanel={false} />
        )}
      </div>
      <CeosSection content={general.content && ceos ? ceos : []} />
      <div id="wpp" className="homeSection s-wpp">
        <i className="icon-whatsapp icon" />
        <p className="sectionTitle">¿Tenés alguna consulta?</p>
        <p className="sectionSubtitle">
          Escribinos por whatsapp y lo resolvemos
        </p>
        <a
          type="button"
          tabIndex={0}
          className="btn"
          href="https://api.whatsapp.com/send?l=es&phone=5492974000358"
        >
          Enviar mensaje
        </a>
      </div>
      <InstagramSection
        content={general.content && instagramPost ? instagramPost : []}
      />
      <div id="footer" className="homeSection s-footer">
        <div className="column">
          <span className="footerTitle">Quiénes Somos</span>
          <p className="footerText">
            {general.content && footer
              ? documentToReactComponents(footer[0].fields.quinesSomos)
              : ''}
          </p>
        </div>
        <div className="column">
          <span className="footerTitle">Dónde Estamos</span>
          <iframe
            // eslint-disable-next-line max-len
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927.199753993814!2d-67.50016584837824!3d-45.86210834944005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbde4558526c7b611%3A0x665cea5c376a17dc!2sMAGNUS%20Fight%20%26%20Fitness!5e0!3m2!1ses!2sde!4v1630337214073!5m2!1ses!2sde"
            width="100%"
            height="200"
            frameBorder="0"
            allowFullScreen={false}
            aria-hidden="false"
            title="map"
          />
        </div>
        <div className="column">
          <span className="footerTitle">Horario</span>
          {footer && (
            <>
              <p className="footerText">
                Lunes a Viernes {general.content && footer[0].fields.horariosLV}
              </p>
              <p className="footerText">
                Sábados {general.content && footer[0].fields.horariosSabado}
              </p>
              <p className="footerText">
                Domingos y feriados nacionales:{' '}
                {general.content && footer[0].fields.horariosDomingo}
              </p>
            </>
          )}
          <hr className="divider" />
          <span className="footerTitle">Nuestras Redes</span>
          <a
            href="https://www.facebook.com/MagnusFightFitness/"
            role="button"
            tabIndex={0}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="icon icon-facebook" />
          </a>
          <a
            href="https://instagram.com/magnusfightfitness/"
            role="button"
            tabIndex={0}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="icon icon-instagram" />
          </a>
        </div>
        <div className="column">
          <span className="footerTitle">Contacto</span>
          {formStatus && formStatus === 100 && (
            <span className="helperText">Enviando mensaje...</span>
          )}
          {formStatus && formStatus === 200 && (
            <span className="helperText">
              Tu mensaje se envió exitosamente.
            </span>
          )}
          {formStatus && formStatus !== 100 && formStatus !== 200 && (
            <span className="errorText">
              Hubo un problema con el envío. Intentá de nuevo en unos minutos.
            </span>
          )}
          <ContactForm onFormSubmit={contactFormSubmit} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
