import { ApiClient, InstagramClient } from './ApiClient';
import { contactFormType } from '../types';

export const SendContactForm = async (values: contactFormType, callback: any) => {
  // TODO: Add proper typing to axios methods
  try {
    const response = await ApiClient.post<any>('/users/contact', { ...values });

    return callback(response.status);
  } catch (err) {
    if (err && err.response) {
      callback(err.status);
      return err;
    }

    throw err;
  }
};

export const FetchInstagramPosts = async (callback: any) => {
  try {
    const response = await InstagramClient.get<any>(
      `/17841410560768777/media?fields=media_url,media_type&access_token=${process.env.REACT_APP_INSTAGRAM_TOKEN}`,
    );

    return callback(response);
  } catch (err) {
    if (err && err.response) {
      callback(err.status);
      return err;
    }

    throw err;
  }
};
