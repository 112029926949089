import React, { FunctionComponent } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { Link } from 'react-router-dom';
import { LogInType, UserType } from '../types';
import { emailRegex } from '../helpers/validators';

const LogInForm: FunctionComponent<LogInType> = ({ onFormSubmit }) => {
  const onSubmit = (values: Pick<UserType, 'email' | 'password'>) => {
    onFormSubmit(values);
  };

  const validate = (values: Pick<UserType, 'email' | 'password'>) => {
    const errors: Partial<Pick<UserType, 'email' | 'password'>> = {};

    if (!values.password) {
      errors.password = 'Campo obligatorio';
    }

    if (!values.email) {
      errors.email = 'Campo obligatorio';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Por favor ingrese un email válido';
    }

    return errors;
  };

  const {
    form, handleSubmit, values, pristine, submitting,
  } = useForm({
    onSubmit,
    validate,
  });

  const email = useField('email', form);
  const password = useField('password', form);

  return (
    <>
      <form className="inscripcion formWrapper" onSubmit={handleSubmit}>
        <div className="inputWrapper">
          <input className="textInput" type="email" {...email.input} placeholder="email" />
          {email.meta.touched && email.meta.error && (
            <span className="errorText">{email.meta.error}</span>
          )}
        </div>
        <div className="inputWrapper">
          <input className="textInput" type="password" {...password.input} placeholder="Password" />
          {password.meta.touched && password.meta.error && (
            <span className="errorText">{password.meta.error}</span>
          )}
        </div>
        <input type="submit" className="btn" value="Ingresar" disabled={pristine || submitting || !values} />
      </form>
      <p>¿No tenés un usuario? <Link to="/registro">Registrate</Link></p>
      <p>¿No te acordás de tu contraseña? <Link to="/pw-olvidado">Recuperala acá</Link></p>
    </>
  );
};

export default LogInForm;
