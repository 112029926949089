import React, { createContext } from 'react';
import { generalContextType } from '../types';
import UseGeneral from '../hooks/generalHook';

export const GeneralContext = createContext({} as generalContextType);

export const GeneralProvider = ({ children }: any) => {
  const { general, manageGeneral } = UseGeneral();

  return <GeneralContext.Provider value={{ general, manageGeneral }}>{children}</GeneralContext.Provider>;
};
