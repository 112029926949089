import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import ClaseCreator from '../components/ClaseCreator';
import { OnSubmitClaseType } from '../types';
import AdminUserTable from '../components/AdminUserTable';
import ClaseMobileHome from '../components/ClaseMobileHome';
import OrderByGymId from '../helpers/orderByGymId';
import { ClasesContext } from '../context/clasesContext';
import { UsersContext } from '../context/usersContext';
import { AuthContext } from '../context/authContext';
import { GeneralContext } from '../context/generalContext';
import { isClasePresent } from '../helpers/clasesHelpers';

const AdminPage = () => {
  const { general, manageGeneral } = useContext(GeneralContext);
  const { status, loading } = general;
  const { users } = useContext(UsersContext);
  const { clases, manageClases } = useContext(ClasesContext);
  const { authUser } = useContext(AuthContext);
  const [sortedUsers, setSortedUsers] = useState(users.sort(OrderByGymId));
  const isUserAdmin = authUser && authUser.roles && authUser.roles.find((role: string) => role === 'Admin');
  const [clasePresent, setClasePresent] = useState(false);

  useEffect(() => {
    if (status && loading) {
      if (status.action === 'createClase'
      || status.action === 'updateAllClases'
      || status.action === 'updateUserAbono'
      || status.action === 'deleteClase'
      || status.action === 'deleteUser'
      || status.action === 'deleteAllStudents'
      || status.action === 'activateUser') {
        manageGeneral({ action: 'setLoading' });
      }
    }
  }, [status, loading, manageGeneral]);

  useEffect(() => {
    if (sortedUsers.length !== users.length) {
      setSortedUsers(users.sort(OrderByGymId));
    }
  }, [users, sortedUsers]);

  const CreateNewClase: OnSubmitClaseType = (clase) => {
    if (isClasePresent(clases, clase)) {
      setClasePresent(true);
      return setTimeout(() => setClasePresent(false), 500);
    }

    manageGeneral({ action: 'setLoading' });
    return manageClases({ action: 'create', dataToUpdate: clase, token: authUser.token });
  };

  if (!isUserAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <div className="pageWrapper admin">
      <i className="icon icon-thumbsup" />
      <h1 className="adminTitle">¡Hola, {authUser.firstName}!</h1>
      <div className="clasesWrapper">
        <i className="icon icon-clases" />
        <p className="sectionTitle">Clases</p>
        <p className="subtitle">Crear una clase nueva</p>
        {status && status.action === 'createClase' && status.code === 201 && (
          <div className="messageWrapper">
            <i className="icon icon-thumbsup" />
            <p className="title">Clase Creada correctamente</p>
            <button type="button" className="btn" onClick={() => status && manageGeneral({ action: 'setStatus', newStatus: undefined })}>
              Crear otra clase
            </button>
          </div>
        )}
        {status
          && status.action === 'createClase'
          && status.code !== 201 && status.code !== 200
          && <p className="errorText">Hubo un error. Intenta nuevamente en unos minutos.</p>}
        {(!status || (status && status.code === 200)) && (
          <ClaseCreator onFormSubmit={CreateNewClase} clasePresent={clasePresent} />
        )}
        <p className="subtitle">Clases existentes</p>
        <ClaseMobileHome isAdminPanel />
      </div>
      <div className="usersWrapper">
        <i className="icon icon-profile" />
        <p className="sectionTitle">Alumnos</p>
        <AdminUserTable sortedUsers={sortedUsers} />
      </div>
    </div>
  );
};

export default AdminPage;
