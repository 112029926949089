import { UserType, ClaseCategoryType } from '../types';

export const emptyUser: UserType = {
  _id: '',
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  gymId: '',
  roles: [],
  token: '',
  isVerified: false,
  abonoRemaining: 0,
};

export const hours: string[] = [
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const minutes: string[] = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

export const days: Record<string, string> = {
  '': '',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
};

export const categories: Record<ClaseCategoryType, string> = {
  martial: 'Artes Marciales',
  fitness: 'fitness',
  indoor: 'Indoor Cycling',
  musculacion: 'Musculación',
  infantil: 'Infantil',
  '': 'Sin categoría',
};
