import React, { useContext } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AdminPage from './pages/Admin';
import ProfilePage from './pages/ProfilePage';
import SignUpPage from './pages/SignUp';
import LogInPage from './pages/LogIn';
import HomePage from './pages/Home';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import Navigation from './components/Navigation';
import Loading from './components/Loading';
import ProtectedRoute from './components/ProtectedRoute';
import './styles/index.scss';
import Verify from './pages/Verify';
import { ClasesProvider } from './context/clasesContext';
import { UsersProvider } from './context/usersContext';
import { AuthProvider } from './context/authContext';
import { GeneralProvider, GeneralContext } from './context/generalContext';

require('typeface-roboto-condensed');
require('dotenv').config();

const BaseLayout = () => {
  const { general } = useContext(GeneralContext);
  const { loading } = general;

  return (
    <>
      {loading && <Loading />}
      <div className={loading ? 'hidden' : 'base'}>
        <Navigation />
        <div className="container">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/login" component={LogInPage} />
            <Route path="/registro" component={SignUpPage} />
            <Route path="/pw-cambio" component={ResetPassword} />
            <Route path="/pw-olvidado" component={ForgotPassword} />
            <Route path="/users/confirmation" component={Verify} />
            <ProtectedRoute path="/admin" component={AdminPage} />
            <ProtectedRoute path="/perfil" component={ProfilePage} />
          </Switch>
        </div>
      </div>
    </>
  );
};

const App = () => (
  <GeneralProvider>
    <AuthProvider>
      <UsersProvider>
        <ClasesProvider>
          <Router>
            <BaseLayout />
          </Router>
        </ClasesProvider>
      </UsersProvider>
    </AuthProvider>
  </GeneralProvider>
);

export default App;
