import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import UserCreator from '../components/UserCreator';
import { UserType } from '../types';
import { GeneralContext } from '../context/generalContext';
import { AuthContext } from '../context/authContext';

const SignUpPage = () => {
  const { general, manageGeneral } = useContext(GeneralContext);
  const { manageAuthUser } = useContext(AuthContext);
  const { status, loading } = general;

  useEffect(() => {
    if (loading) {
      manageGeneral({ action: 'setLoading' });
    }
  }, []);

  useEffect(() => {
    if (status && status.action === 'signup' && loading) {
      manageGeneral({ action: 'setLoading' });
    }
  }, [status, loading, manageGeneral]);

  const submitUser = (user: UserType) => {
    manageGeneral({ action: 'setLoading' });
    manageAuthUser({ action: 'signup', signUpUser: user });
  };

  return (
    <div id="register" className="pageWrapper">
      <h1 className="title">Registrarse</h1>
      {status && status.action === 'signup' && status.code === 201
        && (
          <>
            <i className="icon icon-heart" />
            <p className="title">¡Gracias por registrarte!</p>
            <p className="subtitle">
              Te enviamos un mail para que confirmes tu cuenta. Por favor revisa tu casilla de correo, incluyendo la bandeja de correo no deseado.
            </p>
            <Link className="btn" to="/" onClick={() => status && manageGeneral({ action: 'setStatus', newStatus: undefined })}>
              Volver
            </Link>
          </>
        )}
      {status && status.action === 'signup' && status.code !== 201 && status.code !== 422
        && (
          <span className="errorText">
            Hubo un problema con la conexión, por favor intentá de nuevo en unos minutos.
          </span>
        )}
      {status && status.action === 'signup' && status.code === 422
        && (
          <span className="errorText">
            Este email o ID ya tienen un usuario asociado. Por favor revisá los datos ingresados.
          </span>
        )}
      {(!status || (status.code !== 201 && status.action === 'signup') || (status.action !== 'signup')) && <UserCreator onFormSubmit={submitUser} />}
    </div>
  );
};

export default SignUpPage;
