import React, { useEffect, useState } from 'react';
import { UserType, AdminUserTableProps } from '../types';
import AdminUserRow from '../components/AdminUserRow';

const AdminUserTable = ({ sortedUsers }: AdminUserTableProps) => {
  const [filteredUsers, setFilteredUsers] = useState(sortedUsers);
  const [clicked, setClicked] = useState('');

  useEffect(() => {
    if (sortedUsers.length !== filteredUsers.length) {
      setFilteredUsers(sortedUsers);
    }
  }, [sortedUsers]);

  const filterUsers = (filter: string) => {
    if (filter === 'notVerified') {
      const newFilteredUsers = filteredUsers.filter((user) => user.isVerified === false);

      if (newFilteredUsers.length === filteredUsers.length) {
        setClicked('');
        setFilteredUsers(sortedUsers);
      } else {
        setClicked(filter);
        setFilteredUsers(newFilteredUsers);
      }
    }

    if (filter === 'lowAbono') {
      const newFilteredUsers = filteredUsers.filter((user) => user.abonoRemaining <= 2);

      if (newFilteredUsers.length === filteredUsers.length) {
        setClicked('');
        setFilteredUsers(sortedUsers);
      } else {
        setClicked(filter);
        setFilteredUsers(newFilteredUsers);
      }
    }
  };

  const searchByName = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value) {
      const newFilteredUsers = sortedUsers.filter((user) => {
        const firstName = user.firstName.toLowerCase();
        const lastName = user.lastName.toLowerCase();
        const query = e.currentTarget.value.toLowerCase();

        if (firstName.includes(query) || lastName.includes(query)) {
          return user;
        }
        return null;
      });
      setFilteredUsers(newFilteredUsers);
      return;
    }

    setFilteredUsers(sortedUsers);
  };

  return (
    <>
      <div className="usersFilter">
        <span className="filterLabel">Filtrar:</span>
        <button
          type="button"
          className={clicked === 'notVerified' ? 'btn small red selected' : 'btn small red'}
          onClick={() => filterUsers('notVerified')}
        >
          Mail sin confirmar
        </button>
        <button
          type="button"
          className={clicked === 'lowAbono' ? 'btn small lightBlue selected' : 'btn small lightBlue'}
          onClick={() => filterUsers('lowAbono')}
        >
          pocas clases en pack
        </button>
        <input type="text" onChange={searchByName} className="userSearchBar" placeholder="Escriba nombre o apellido del usuario para buscar..." />
      </div>
      <ul>
        {filteredUsers.length === 0 ? <p className="helperText">No hay usuarios</p>
          : filteredUsers.map((user: UserType) => (
            <AdminUserRow {...user} key={user._id} />
          ))}
      </ul>
    </>
  );
};

export default AdminUserTable;
