import React, { FunctionComponent } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { Link } from 'react-router-dom';
import { UserType, UserCreatorType, userCreatorFormValues } from '../types';
import { emailRegex, numberRegex, passwordRegex } from '../helpers/validators';

const UserCreator: FunctionComponent<UserCreatorType> = ({ onFormSubmit }) => {
  const onSubmit = (values: userCreatorFormValues) => {
    const preparedUSer: UserType = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      gymId: values.gymId,
      password: values.password,
      roles: new Array('User'),
      isVerified: false,
      abonoRemaining: 0,
    };

    onFormSubmit(preparedUSer);
  };

  const validate = (values: any) => {
    const errors: Partial<userCreatorFormValues> = {};

    if (!values.firstName) {
      errors.firstName = 'Campo obligatorio';
    } else if (values.firstName.length < 2) {
      errors.firstName = 'Tu nombre debe tener al menos 2 caracteres.';
    }
    if (!values.lastName) {
      errors.lastName = 'Campo obligatorio';
    } else if (values.lastName.length < 2) {
      errors.lastName = 'Tu Apellido debe tener al menos 2 caracteres.';
    }
    if (!values.gymId) {
      errors.gymId = 'Campo obligatorio';
    } else if (!numberRegex.test(values.gymId)) {
      errors.gymId = 'Tu ID del gimnasio debe tener solo digitos numéricos';
    } else if (values.gymId.length > 4 || values.gymId.length < 4) {
      errors.gymId = 'Tu ID del gimnasio debe tener 4 dígitos';
    }
    if (!values.password) {
      errors.password = 'Campo obligatorio';
    } else if (!passwordRegex.test(values.password)) {
      errors.password = 'tu contraseña debe tener al menos 6 caracteres, una mayúscula, una minúscula y un número';
    }
    if (!values.email) {
      errors.email = 'Campo obligatorio';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'Por favor ingrese un email válido';
    }

    return errors;
  };

  const {
    form, handleSubmit, values, pristine, submitting,
  } = useForm({
    onSubmit,
    validate,
  });

  const firstName = useField('firstName', form);
  const lastName = useField('lastName', form);
  const email = useField('email', form);
  const password = useField('password', form);
  const gymId = useField('gymId', form);

  return (
    <>
      <form className="inscripcion formWrapper" onSubmit={handleSubmit}>
        <div className="inputWrapper">
          <input className="textInput" type="email" {...email.input} placeholder="email" />
          {email.meta.touched && email.meta.error && (
          <span className="errorText">{email.meta.error}</span>
          )}
        </div>
        <div className="inputWrapper">
          <input className="textInput" type="password" {...password.input} placeholder="Password" />
          {password.meta.touched && password.meta.error && (
          <span className="errorText">{password.meta.error}</span>
          )}
        </div>
        <div className="inputWrapper">
          <input className="textInput" {...firstName.input} placeholder="Nombre" />
          {firstName.meta.touched && firstName.meta.error && (
          <span className="errorText">{firstName.meta.error}</span>
          )}
        </div>
        <div className="inputWrapper">
          <input className="textInput" {...lastName.input} placeholder="Apellido" />
          {lastName.meta.touched && lastName.meta.error && (
          <span className="errorText">{lastName.meta.error}</span>
          )}
        </div>
        <div className="inputWrapper">
          <input className="textInput" type="text" {...gymId.input} placeholder="ID del gimnasio" />
          {gymId.meta.touched && gymId.meta.error && (
          <span className="errorText">{gymId.meta.error}</span>
          )}
          <p className="helperText">Este es tu número de afiliado en el gimnasio. Si no estás seguro de cuál es, contactanos! </p>
        </div>
        <input type="submit" className="btn" value="Registrarme" disabled={pristine || submitting || !values} />
      </form>
      <p>¿Ya tenés un usuario? <Link to="/login">Ingresá</Link></p>
    </>
  );
};

export default UserCreator;
